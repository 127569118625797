import React, { useState } from 'react'
import 'react-pro-sidebar/dist/css/styles.css';
import Switch from 'react-switch';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import { Tabs, Tab } from 'react-bootstrap'
import CategoriesTab from './CategoriesTab';
import ChannelTab from './ChannelTabs';
import { FaTachometerAlt, FaList, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import rArrow from '../../../../img/svg/open-arrow.svg';
import lArrow from '../../../../img/svg/close-arrow.svg';


const Sidebar = ({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }) => {
    const [Collapse, setCollapse] = useState(true)
    const HandelCollapse = () => {
        setCollapse(!Collapse)
        handleCollapsedChange(Collapse)
    }
    return (
        <ProSidebar
            className="sidebar-scrollbar"
            id="style-3"
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="lg"
            onToggle={handleToggleSidebar}
        // style={{ height: "600px" }}
        >

            <SidebarHeader>
                <div className="block " onClick={() => HandelCollapse()}>
                    {Collapse ? <span data-toggle="tooltip" data-placement="bottom" title="Click to Collapse Menu"><img src={lArrow} className="left-arrow" /></span> : <span data-toggle="tooltip" data-placement="bottom" title="Click to Open Menu"><img src={rArrow} className="right-arrow" /></span>}
                    {/* <Switch
                        height={16}
                        width={30}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={handleCollapsedChange}
                        checked={collapsed}
                        onColor="#219de9"
                        offColor="#bbbbbb"
                    /> */}
                </div>
            </SidebarHeader>

            <SidebarContent>
                <Tabs
                    defaultActiveKey="Categories"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-6"
                >
                    <Tab eventKey="Categories" title="Categories">
                        <CategoriesTab />
                    </Tab>
                    <Tab eventKey="Channel" title="Channels" >
                        <ChannelTab />
                    </Tab>

                </Tabs>
            </SidebarContent>



        </ProSidebar>
    );
};

export default Sidebar
