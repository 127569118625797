import React, { Component } from 'react';
import ReactBootstrap, { Jumbotron, Navbar, Nav, Button, Col, Grid, Panel, FormGroup, Card, div } from 'react-bootstrap';
import './Footer.css'


class About extends Component {
    state = {}
    render() {
        return (
            <div className="page-content container-fluid">
                    <h1 className="page-header" >About us</h1>
                    <div className="header-border"></div>
                
                <p className="page-para"><a href="https://aawaz.com/">aawaz.com</a> is the largest spoken-word audio and podcast network in Indian languages. We are currently available in Hindi, English, Marathi and Urdu. All content on aawaz is original & created by us that are clean, safe, informative & entertaining.
aawaz is a product of <a href="https://agrahyah.com/">Agrahyah Technologies</a>.</p> <br />
                <strong className="sub-header">Brand Purpose:</strong><p className="page-para"> To elevate and entertain people by kindling their imaginations through audio storytelling and invigorating conversations as podcasts
</p> <br />
                <strong className="sub-header">
                    Brand differentiators:
                </strong>
                <p className="page-para"><ul>
                    <li>100% Original Content and brand safe</li>
                    <li>Multi-lingual. English, Hindi, Urdu & Marathi</li>
                    <li>Available across devices and end points (Android, IOS, KaiOS, IndusOS, FireTVStick, Jio STB, Ola Play, Web)</li>
                    <li>Free and ad-free streaming with offline listening features</li>
                    <li>First of its kind LIVE audio programming with professional RJs for maximum audience engagement</li>
                </ul></p> <br />
                
                <a  className="page-link" target="_blank" href="https://drive.google.com/file/d/18UhJawU_oRHFuCLk60d6IZiGd0AG7awr/view">View/download our credentials presentation</a> 
                <br />
                <strong  className="sub-header">aawaz for brands & marketers:</strong>
                <p className="page-para">Leading companies like Google, IBM, Agora, Tata Mutual Fund, Tata Trusts, Amity University, The Economic Times among others, trust aawaz to be their podcast partner in creating immersive listening experiences. We offer end-to-end podcast services & advertising solutions. If you are looking at creating compelling audio experiences as podcasts or spoken-word audio, then contact Sreeraman: <a href="mailto:sreeraman@agrahyah.com">sreeraman@agrahyah.com</a> | <a href="tel:+91-9769020148">+91-9769020148</a></p> <br />
                
                <a className="page-link"  target="_blank" href="https://drive.google.com/file/d/1sqRfi83aIzZfCFTVGt-XZAJIfOGYkfbI/view">View/download our credentials presentation</a> 
                <br />  
                <strong className="sub-header" >aawaz for publishers:</strong> <br />
                <p className="page-para">HT Smartcast, BIG FM, The Economic Times, among other leading publishers trust aawaz to unlock new audiences and amplify their reach. Whether you are looking at distributing on aawaz or creating Live Experiences on #aawazLive, we offer attractive syndicate deals for institutional publishers.Contact Sreeraman to explore: <a href="mailto:sreeraman@agrahyah.com">sreeraman@agrahyah.com </a> | <a href="tel:+91-9769020148">+91-9769020148</a></p> <br />
                <strong  className="sub-header">aawaz for content creators:</strong>
                <p className="page-para">We work with creative talent across languages and genres to create high-quality podcasts & audio experiences. If you are a voice-over artist, RJ, or a storyteller, then write to <a href="mailto:rushabh@agrahyah.com">rushabh@agrahyah.com</a> | <a href="tel:+91-9819880110">+91-9819880110</a>.</p>

            </div>
        );
    }
}

export default About;