import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import CategoryList from './footerCat';
import ChannelList from './footerChannel';
import FooterSocial from './footerSocial';
import FooterClient from "./footerClient";
import FooterMenu from './footerMenu';
import FooterPopularPoadcast from './footerPopularPoadcast'
import ScrollToTop from './scrollToTop';
import BorderTop from '../../../../img/borderTop.png';



class footerSec extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="dfooter">
                <ScrollToTop />
                <Container>
                    <div className="footer-section2">
                        <img src={BorderTop} style={{ width: "100%", height: '2px' }} />
                        <FooterSocial />
                    </div>
                    <div className="footer-section1">
                        <FooterClient />
                    </div>
                    <div className="footer-section">
                        <Row>
                            <Col>
                                <h5 className="footer-title">Categories</h5>
                                <CategoryList />
                            </Col>
                            <Col>
                                <h5 className="footer-title">Channels</h5>
                                <ChannelList />
                            </Col>
                            <Col>
                                <h5 className="footer-title">Popular Podcasts</h5>
                                <FooterPopularPoadcast />
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-section3">
                        <FooterMenu />
                    </div>
                </Container>
            </div>
        );
    }
}

export default footerSec;