import React from 'react';
import { Nav, Col, Container } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import './FooterNav.css';
import HomeIcon from './ic_nav_home.svg'
import ActiveHomeIcon from './ic_nav_home_active.svg'
import MyShowIcon from './ic_nav_mycontent.svg'
import ActiveMyShowIcon from './ic_nav_mycontent_active.svg'
import SearchIcon from './ic_nav_search.svg'

import Category from "./category.svg";
import ActiveCategory from "./category_hover.svg";
import Channel from "./channel.svg";
import ActiveChannel from "./channel_hover.svg";
import Live from "./live_icon.svg";
import ActiveLive from "./live_icon_hover.svg";


import ActiveSearchIcon from './ic_nav_search_active.svg'
import ProfileIcon from './ic_nav_profile.svg'
import ActiveProfileIcon from './ic_nav_profile_active.svg'
import LiveBtn from '../../../img/live-g-icon.gif';

const Footer = (props) => {

    return (
        <React.Fragment>
            <Nav
                className="footer-nav mobile-only"
                activeKey="/"
                exact="true"
                onSelect={selectedKey => alert(`selected ${selectedKey}`)}
                style={{ display: props.playerVisible ? "none" : "" }}
            >
                <Col xs={4}>
                    <Nav.Item>

                        <NavLink to="/" exact={true} >

                            <img width="25" src={window.location.pathname === "/" ? ActiveHomeIcon : HomeIcon} alt="Home" />

                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15.75" viewBox="0 0 18 15.75">
                                <path className='active-list-item' d="M9,2.25a9,9,0,0,0-9,9v1.688a1.125,1.125,0,0,0,.622,1.006l.506.253A3.937,3.937,0,0,0,5.063,18h.844a.844.844,0,0,0,.844-.844V10.969a.844.844,0,0,0-.844-.844H5.063A3.925,3.925,0,0,0,2.25,11.307V11.25a6.75,6.75,0,1,1,13.5,0v.057a3.925,3.925,0,0,0-2.812-1.182h-.844a.844.844,0,0,0-.844.844v6.187a.844.844,0,0,0,.844.844h.844a3.937,3.937,0,0,0,3.935-3.8l.506-.253A1.125,1.125,0,0,0,18,12.938V11.25A9,9,0,0,0,9,2.25Z" transform="translate(0 -2.25)" fill="#3e3e3e" opacity="1" />
                            </svg> */}
                            <p>AAWAZ</p>
                        </NavLink>
                    </Nav.Item>
                </Col>
                <Col xs={4}>
                    <Nav.Item>
                        <NavLink to="/nav-tab/0" exact={true} >
                            <img className="footer-chan-icon" src={window.location.pathname === "/nav-tab/0" ? ActiveChannel : Channel} alt="Home" />
                            <p>CHANNEL</p>
                        </NavLink>
                    </Nav.Item>
                </Col>
                <Col xs={4}>
                    <Nav.Item>

                        <NavLink to="/nav-tab/1" exact={true} >
                            <img className="footer-cat-icon" src={window.location.pathname === "/nav-tab/1" ? ActiveCategory : Category} alt="Search" />
                            <p>CATEGORY</p>
                        </NavLink>
                    </Nav.Item>
                </Col>
                {/* <Col xs={3}>
                    <Nav.Item>
                        <NavLink to="/nav-tab/2" exact={true} >
                            <img className="footer-liv-icon" src={window.location.pathname === "/nav-tab/2" ? ActiveLive : Live} alt="Profile" />
                            <p>LIVE</p>
                        </NavLink>
                    </Nav.Item>
                </Col> */}
            </Nav>
        </React.Fragment>
    )

}

export default Footer