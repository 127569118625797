import React, { Component } from 'react';
import './Footer.css'

class Media extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="container mb-5">   

            <div id="exTab2" className="container mt-3">
                <div className="row tab-box p-3">
                    <div className="col mediadivide">
                    <a className="" href="#1" data-toggle="tab"><i className="et-browser"></i> Online Media Coverage</a>
                    </div>
                    <div className="col">
                    <a className="" href="#2" data-toggle="tab"><i className="et-newspaper"></i> Print Media Coverage</a>
                    </div>
                </div>    	
    
    
                <div className="tab-content ">
                    <div className="tab-pane active" id="1">
                        <div className="row p-3">
                        <div className="col-md-3 online-card">           
                            <div className="card">
                                <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                    <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                                    <div className="card-block p-3">               
                                        <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                            <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                        </div>
                                    </div>
                                </a>
                                    <div className="card-footer mt-4">                
                                        <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                            Read more
                                        </a>
                                    </div>
                            </div>               
                         </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>
                <div className="col-md-3 online-card">           
                      <div className="card">
                          <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                              <img className="card-img-top" src="https://agrahyah.com/img/media/et-agrahyah.jpg" />
                              <div className="card-block p-3">               
                                  <h5 className="card-title mt-3">Streaming platforms see growth explosion as pandemic propels podcasts to popularity</h5>
                                      <div className="card-text mt-2">MUMBAI: The coronavirus disruption has launched India’s media landscape into the third V front of voice, after video and vernacular. Only one third of Indians own a smartphone, and even fewer are conversationally fluent in English, given that the opening of the voice frontier promises a deepening of the digital media in the ocuntry.
                                  </div>
                              </div>
                          </a>
                              <div className="card-footer mt-4">                
                                  <a href="https://economictimes.indiatimes.com/industry/media/entertainment/streaming-platforms-see-growth-explosion-as-pandemic-propels-podcasts-to-popularity/articleshow/78601129.cms" target="_blank">
                                      Read more
                                  </a>
                              </div>
                      </div>               
                </div>           
            </div>
                    </div>
                    <div className="tab-pane" id="2">
                    <div className="panel-group mt-3" id="accordion">
                    <div className="panel panel-default">
                    <div className="panel-heading accordion-toggle" data-toggle="collapse" data-parent="#accordion" data-target="#collapseOne" aria-expanded="true">
                        <h4 className="panel-title"> Urdu Coverage</h4>
    
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse in">
                        <div className="panel-body">
                        <div className="row p-3">
                            <div className="col-md-3 print-card">  
                                <div className="card">
    
                                    <img alt="Media-Img" alt="Media-Img" className="card-img-top" src="https://storage.googleapis.com/aawaz-stateless/2020/05/Daily-Sangam-Urdu.jpg" />
    
                                    <div className="media-content">
                                    <h4 >21st May, 2020</h4>
                                    <img id="myImg"   data-toggle="modal" data-target="#myModal7" 
                                    src="https://storage.googleapis.com/aawaz-stateless/2020/06/Daily-Sangam-Urdu_Patna_pg02_21.05.2020-aawaz.com_.jpg" alt="The Daily Lazwal, 21st May, 2020" width="300" height="200" />
                                    </div>
                                
                                    <div id="myModal" className="modal">
                                    <span className="close">×</span>
                                
                                    <img className="modal-content" id="img01" />
                                
                                    <div id="caption">The Daily Lazwal, 21st May, 2020</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 print-card">  
                                <div className="card">
    
                                    <img alt="Media-Img" alt="Media-Img" className="card-img-top" src="https://storage.googleapis.com/aawaz-stateless/2020/05/Daily-Sangam-Urdu.jpg" />
    
                                    <div className="media-content">
                                    <h4 >21st May, 2020</h4>
                                    <img id="myImg"   data-toggle="modal" data-target="#myModal7" 
                                    src="https://storage.googleapis.com/aawaz-stateless/2020/06/Swadesh_Indore_pg04_31.05.2020-Aawaz.com_.jpg" alt="The Daily Lazwal, 21st May, 2020" width="300" height="200" />
                                    </div>
                                
                                    <div id="myModal" className="modal">
                                    <span className="close">×</span>
                                
                                    <img className="modal-content" id="img01" />
                                
                                    <div id="caption">The Daily Lazwal, 21st May, 2020</div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="panel panel-default">
                    <div className="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseTwo">
                        <h4 className="panel-title">Hindi Coverage</h4>
    
                    </div>
                    <div id="collapseTwo" className="panel-collapse collapse">
                        <div className="panel-body">
                        <div className="row p-3">
                            <div className="col-md-3 print-card">  
                            <div className="card">
    
                                <img alt="Media-Img" alt="Media-Img" className="card-img-top" src="https://storage.googleapis.com/aawaz-stateless/2019/10/16980eeb-swades-logo.jpg" />
    
                                <div className="media-content">
                                <h4 >21st May, 2020</h4>
                                <img id="myImg"   data-toggle="modal" data-target="#myModal7" 
                                src="https://storage.googleapis.com/aawaz-stateless/2020/06/Swadesh_Indore_pg04_31.05.2020-Aawaz.com_.jpg" alt="The Daily Lazwal, 21st May, 2020" width="300" height="200" />
                                </div>
                            
                                <div id="myModal" className="modal">
                                <span className="close">×</span>
                            
                                <img className="modal-content" id="img01" />
                            
                                <div id="caption">The Daily Lazwal, 21st May, 2020</div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div id="myModal7" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">         
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                                <div className="modal-body">
                                    <img className="showimage img-responsive" src="" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
    
    
    
        
                </div>
      </div>
    
        </div>
        </div> 
         );
    }
}
 
export default Media;