import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import auth from '../../../../services/authentication';
import { Container, Row, Col, Card } from 'react-bootstrap';

class ChannelList extends React.Component {
  state = {
    channels: []
  }

  componentDidMount() {
    if (this.state.channels.length === 0) {
      axios.get(`https://prod.aawaz.com/web/channel-details/?all=True`, { auth: auth })
        .then(res => {
          const channels = res.data;
          this.setState({ channels });
        })
    }
  }

  render() {
    return (
      <div>
        <ul className="footer-menu">
          {this.state.channels.map(channel =>
            <li key={channel.uniqueSlug}>
              <Link to={`/channel/` + channel.shortUrl}>
                {channel.title}
              </Link>
            </li>
          )}
        </ul>
      </div>
    )
  }
}
export default ChannelList