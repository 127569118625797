import React, { useState, useEffect } from 'react';
import Thumbnail from '../../../img/thumbnail.jpg';
import './read-responsive.css';
import axios from 'axios'
import { withRouter, Link } from 'react-router-dom';

const SingleRead = (props) => {

    const [Data, setData] = useState({})
    let { id } = props.match.params



    const OnLoad = (id) => {
        axios.get(`https://read.aawaz.com/api/live/read/getSingleArticle/${id}/`)
            .then((res) => {
                console.log(" singel read data", res.data)
                setData(res.data)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
    }
    useEffect(() => {
        OnLoad(id)
    }, [id])
    return (
        <div>
            <div className="container">
                <div className="read-menu">
                </div>
                <span className="read-category-name">
                    <p>{Data.category_name}</p>
                </span>
                <h2 class="post-name">{Data.title}</h2>
                <p className="author-name">{Data.author} -  <span className="pubdate">{Data.pubDate}</span> </p>
                <img src={Data.image} className="post-img" alt="" />
                <div dangerouslySetInnerHTML={{ __html: Data.content }} />
            </div>
        </div>
    )
}

export default withRouter(SingleRead)
