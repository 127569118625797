import React from 'react'
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Image } from "react-bootstrap";
import wifiLogo from '../../../img/ic_nowifi_empty@2x.png'


const NoConnection = (props) => {

    return (
        <Container style={{marginTop: '25vh'}}>
            <Row>
                <Col style={{textAlign: 'center'}}>
                    <Image src={wifiLogo} fluid />
                </Col>
            </Row>
            <Row>
                <Col>                  
                    <p>No Internet Connection</p>
                </Col>
            </Row>
        </Container>
    )
}

export default withRouter(NoConnection);