import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Nav, Navbar, Col, Dropdown, ToggleButtonGroup, Button } from 'react-bootstrap'
import userLogin from '../../../img/user-profile.svg'
import HeaderSearch from './HeaderSearch';
import SearchIcon from '../../../img/search.png';
import { YoloProvider } from 'react-googleyolo'
import SideNav from './SideNav'
import Logo from '../../../img/logo.png';
import LiveBtn from '../../../img/svg/live-btn-gif.gif';
import MoreBtn from '../../../img/svg/more-nav.svg';
import langBtn from '../../../img/language-icon.svg'
import darkBtn from '../../../img/dark-btn.svg'
import languageIcon from '../../../img/icons/language-icon.svg'
import languageActiveIcon from '../../../img/icons/language-active.svg'

import '../../../css/pages.css'
import btn from './img.png.png'
import firebase from 'firebase/app';
export default class DesktopHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { user } = this.props
        return (

            <React.Fragment>
                <div className="background">
                    <div className="desktop-only header-nav">
                        <Navbar bg="white" variant="light" className="sticky-navbar bg-transparent">
                            {/* <span className="hamberg desktop-only">
                                <i class="fas fa-bars menu-icons1">
                                    <SideNav />
                                </i>
                            </span> */}
                            <Col lg={1} md={1}>
                                <Navbar.Brand
                                // href="#home" onClick={() =>props.history.push(`/`)}
                                >
                                    <Link to={`/`}>
                                        <img alt='' src={Logo} className="logo d-flex justify-content-center flex-wrap" />
                                    </Link>

                                </Navbar.Brand>
                            </Col>
                            <Col lg={3} md={1}>
                            </Col>
                            <Col lg={6} md={7}>
                                <Nav className="main-menu  ml-4 desktop-nav">
                                    <NavLink exact activeClassName="activeheader" to="/"><span className="headerHover">Home</span></NavLink>
                                    <NavLink exact activeClassName="activeheader" to="/categories"><span className="headerHover">Categories</span></NavLink>
                                    <NavLink exact activeClassName="activeheader" to="/channels"><span className="headerHover">Channels</span></NavLink>
                                    <a exact activeClassName="activeheader" href="https://read.aawaz.com/"><span className="headerHover">Read</span></a>
                                    <NavLink exact activeClassName="activeheader" to="/live/show-list"> <img width="10" src={LiveBtn} alt="img" className="live-gif" /></NavLink>
                                    <a activeClassName="activeheader" >
                                        <span className="dropdownHeaderMore-container" tabindex="-1">
                                            <img src={MoreBtn} className="info-icon" />
                                            <div className="dropdownHeaderMore" >
                                                <NavLink exact activeClassName="activeheader" to="/contact"><div className="headerHover">Contact</div></NavLink>
                                                <NavLink exact activeClassName="activeheader" to="/about-us"><div className="headerHover">About</div></NavLink>
                                            </div>
                                        </span>
                                    </a>
                                    {/* <a exact activeClassName="activeheader" href="https://live.aawaz.com"><span className="live-btn"> <img width="30" src={LiveBtn} alt="img" /> Live</span></a> */}

                                </Nav>
                            </Col>
                            <Col lg={1} md={1}></Col>
                            <Col lg={1} className="right-menu" md={1}>
                                <span data-toggle="tooltip" data-placement="bottom" title="Select your Language" onClick={() => this.props.userLangPrefBottomPrompt()} style={{ cursor: "pointer", paddingRight: "21px" }} > <img src={this.props.defaultUserLangPref ? (this.props.defaultUserLangPref.length >= 1) && this.props.defaultUserLangPref.length < 4 ? languageActiveIcon : languageIcon : languageIcon} alt="img" /></span>
                                {/* <span data-toggle="tooltip" data-placement="bottom" title="Select your Theme" style={{ paddingRight: "16px" }}><img src={darkBtn} /> </span> */}
                                <span data-toggle="tooltip" data-placement="bottom" title="Login Here">
                                    <YoloProvider
                                        clientId={process.env.REACT_APP_GOOGLE_CREDENTIALS_CLIENT_ID}
                                        onRetrieveSuccess={this.onRetrieveSuccess}
                                        onRetrieveFailure={this.onRetrieveFailure}
                                    >
                                        <span style={{ cursor: "pointer" }} data-tip="Login here">
                                            {user === null || user.isAnonymous ? <img alt="" className="user-btn" style={{ borderRadius: '50%', marginRight: "18px" }} src={userLogin} onClick={() => this.props.toggleLoginPrompt()} />
                                                :
                                                <span className="dropdownHeaderProfile-container" tabindex="-1">
                                                    <img alt="user" style={{ height: '30px', width: '30px', borderRadius: '50%' }} className="Profile-icon" onLoad={(e) => { e.target.src = user.photoURL || userLogin }} src={userLogin} onError={(e) => { e.currentTarget.src = userLogin }} />
                                                    <div className="dropdownHeaderProfile" >
                                                        <div onClick={() => {
                                                            firebase.auth().signOut()
                                                            localStorage.removeItem("firebaseui::rememberedAccounts")


                                                        }}>Logout</div>
                                                    </div>
                                                </span>
                                                // <Dropdown>
                                                //     <Dropdown.Toggle variant="link" id="dropdown-basic" className='ProfileImg'>
                                                //         <img alt="user" style={{ height: '30px', width: '30px', borderRadius: '50%' }} onLoad={(e) => { e.target.src = user.photoURL || userLogin }} src={userLogin} onError={(e) => { e.currentTarget.src = userLogin }} />
                                                //     </Dropdown.Toggle>

                                                //     <Dropdown.Menu style={{ left: "-90px" }}>
                                                //         <p onClick={() => {
                                                //             firebase.auth().signOut()
                                                //             localStorage.removeItem("firebaseui::rememberedAccounts")


                                                //         }}>Logout</p>
                                                //     </Dropdown.Menu>
                                                // </Dropdown>
                                            }
                                        </span>
                                    </YoloProvider>

                                </span>
                            </Col>
                        </Navbar>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
