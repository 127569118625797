import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    play: false
}

const userSlice = createSlice({
    name: "player",
    initialState,
    reducers: {
        setPlay: (state, action) => {
            state.play = action.payload;
            console.log("stare", state, "acation", action)
        },
        setPause: (state, action) => {
            state.play = action.payload;
            console.log("stare", state, "acation", action)
        },
    }
})

export const { setPause, setPlay } = userSlice.actions;

export const selectPlayPause = (state) => state.player.play;


export default userSlice.reducer;