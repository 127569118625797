
import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
// import firebaseui from 'FirebaseUI';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import "./Login.css"
import Logo from '../../../img/logo.png';
import { YoloProvider } from 'react-googleyolo';
import LoginScreen from '../../../img/login-screen.jpg';

// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebase from 'firebase'


class Login extends Component {
    constructor(props) {
        super()
    }
    data = null;
    // Hold a reference to the anonymous current user.
    anonymousUser = firebase.auth().currentUser;
    uiConfig = {
        // autoUpgradeAnonymousUsers: true,
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        // signInSuccessUrl: '/profile',
        signInOptions: [
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,

                defaultCountry: 'IN',

            },
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                clientId: '146628349653-bgebh7vc3bmbibh3pnp5mkfo77b754gt.apps.googleusercontent.com'

            },
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],

        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => {
                // this.props.history.push('/profile')
                console.log('signIn was successful!')
                this.props.toggleLoginPrompt()
                this.props.history.push('/')
            },
            signInFailure: (error) => {
                console.log('signIn failed!')
                if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
                    console.log('anonymous-upgrade-merge-conflict!')
                    return Promise.resolve();
                }
                var cred = error.credential;
                // If using Firebase Realtime Database. The anonymous user data has to be
                // copied to the non-anonymous user.
                var app = firebase.app();
                // Save anonymous user data first.
                return app.database().ref('users/' + firebase.auth().currentUser.uid)
                    .once('value')
                    .then(function (snapshot) {
                        this.data = snapshot.val();
                        // This will trigger onAuthStateChanged listener which
                        // could trigger a redirect to another page.
                        // Ensure the upgrade flow is not interrupted by that callback
                        // and that this is given enough time to complete before
                        // redirection.
                        return firebase.auth().signInWithCredential(cred);
                    })
                    .then(function (user) {
                        // Original Anonymous Auth instance now has the new user.
                        console.log('Original Anonymous Auth instance now has the new user')
                        return app.database().ref('users/' + user.uid).set(this.data);
                    })
                    .then(function () {
                        // Delete anonymnous user.
                        console.log('Delete anonymnous user')
                        return this.anonymousUser.delete();
                    }).then(function () {
                        // Clear data in case a new user signs in, and the state change
                        // triggers.
                        this.data = null;
                        console.log('FirebaseUI will reset and the UI')
                        // FirebaseUI will reset and the UI cleared when this promise
                        // resolves.
                        // signInSuccessWithAuthResult will not run. Successful sign-in
                        // logic has to be run explicitly.
                        this.props.history.push('/')
                    });
            }
        },
        credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    }

    render() {
        return (
            this.props.user ?
                <div>
                    <Row>
                        <Col>
                            <p className="login-txt">Fast & Free <br />
                                Log in or Sign Up!</p>
                            <Container className="userLogin">
                                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                            </Container>
                            <p className="login-policy-txt">By continuing, you are indicating that you accept our <Link to={`/terms-of-use`}>Terms of Service</Link> and <Link to={`/privacy-policy`}>Privacy Policy</Link>.</p>

                        </Col>
                        {/* <Col lg={6}>
                            <img width="100%" src={LoginScreen} />
                        </Col> */}
                    </Row>

                </div> : null
        );
    }
}

export default withRouter(Login);