import React, { Component } from 'react';
import './Footer.css'

class TermsOfUse extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
            <div className="page-content container-fluid">
            <h1 className="page-header" >Terms of use</h1>
            <div className="header-border"></div>
                 <p className="page-para">This document is an electronic record in terms of the Information Technology Act, 2000 and rules thereby under as applicable and various other statutes. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p> <br />
                <p className="page-para">
                This is a legal and binding agreement between you, the user ("User" or "You") of the Services, as defined below, and Samor Culture Labs Pvt. Ltd, a subsidiary of Agrahyah Technologies Private Limited, a company incorporated under the Companies Act, 1956, having its registered office at Shop No.2, Sej Plaza, Nr. Nutan Vidya Mandir School, Marve Road, Malad West Mumbai Mumbai City - 400064, Maharashtra, India (the " Company"), stating the terms that govern your use of the Website and the App (as defined below). By accepting these terms of use in any manner or accessing this Website and/ or App, you consent, agree and undertake to abide, be bound by and adhere to the terms of use and the privacy policy accessible at privacy policy and any other terms which govern the Website and/ or App (together referred to as "Terms of Use" or "Terms" or "Terms and Conditions") and if you do not agree to these terms of use, you are not entitled to avail of / use the services and any use thereafter shall be unauthorized.
                </p>
                <br />
                <strong className="sub-header">
                1) DESCRIPTION OF SERVICE AND ACCEPTANCE OF TERMS OF USE
                </strong>
                <p className="page-para">The Company inter alia provides an online Website https://aawaz.com as an interactive platform for its users and any associated mobile sites, applications ("Website ") and any associated mobile sites, applications (“Apps”)and inter alia contains a repertoire of audio content that provokes thought, kindles joy, immerses in stories from mythology to fables, and informs to live a better life through expert conversations in health, wellness, relationships, and career and or other services offered from time to time by the Company in connection with its business, provided on the Website and/ or App ("Company Content") and includes posting of comments, participation in contests. The Website and/ or App may also provide you content by integrating services and application of other audio video streaming platforms ("Partner Content") and/or provide hosting service to third parties to display their content ("Third Party Content") (all such features of the Website and/ or App collectively referred to as "Services"). For the purposes stated herein, “Content” includes “Company Content” and “Third Party Content”.</p>
                <br />
                <p className="page-para">The Company Content and the Partner Content will be made available to You through different modes, at the discretion of the Company, including via on demand basis at the User's discretion at a time chosen by You and download for offline usage by You. Please note that the availability of, and Your ability to access, the Content or some part of Services, (a) is subject to the Company's sole discretion and (b) may be dependent upon Your geographical location and (c) is on the condition that not all Content or Services will be available to all users. On account of the nature of the Internet, this Website and/ or App and the Services may also be accessed in various geographical locations; and You hereby agree and acknowledge that You are accessing this Website and/ or App and availing of the Services, at Your own risk, choice and initiative and You agree and undertake to ensure that Your use of the Website and/ or App and the Services complies with all applicable laws including the local laws in Your jurisdiction. Further, such Services and Content may vary from place to place, time to time and device to device and would be subject to various parameters such as specifications, device, Internet availability and speed, bandwidth, etc. You agree and acknowledge that the Company may monitor or use certain technologies for monitoring of activities including logging of Your IP address to ascertain and verify Your geographical location. To facilitate Your usage and access, the Services can be packaged by the Company through different models such as, Content or Services may be accessible (a) free of charge which may include advertisements or commercials or (b) via subscription through payment of a subscription fee or (c) a pay-per-use model with or without advertisements/commercials or (d) with a combination of the foregoing on the Website and/ or App.</p>
                <br />
                <p className="page-para">The Service can be availed through platforms, software and devices, which the Company approves from time to time, the current list for which is as set out below ("Compatible System"). In some cases, whether a device is (or remains) a Compatible System may depend on software or platform provided or maintained by the device manufacturer or other third parties. As a result, devices that are Compatible System at one time may cease to be Compatible System in the future.</p>
                <br />
                <p className="page-para">The Service can be availed through platforms, software and devices, which the Company approves from time to time, the current list for which is as set out below ("Compatible System"). In some cases, whether a device is (or remains) a Compatible System may depend on software or platform provided or maintained by the device manufacturer or other third parties.</p>
                <br />
                <p className="page-para">As a result, devices that are Compatible System at one time may cease to be Compatible System in the future.</p>
                <br />
                <strong className="sub-header">
                2) USE OF THE SERVICES IS SUBJECT TO COMPLIANCE WITH THESE TERMS OF USE.
                </strong>
                <p className="page-para">The Service can be availed through platforms, software and devices, which the Company approves from time to time, the current list for which is as set out below ("Compatible System"). In some cases, whether a device is (or remains) a Compatible System may depend on software or platform provided or maintained by the device manufacturer or other third parties.</p>
                <br />
                <p className="page-para">As a result, devices that are Compatible System at one time may cease to be Compatible System in the future.</p>
                <br />
                <p className="page-para">SOME CONTENT OFFERED ON THE WEBSITE AND/ OR APP MAY NOT BE SUITABLE FOR SOME USERS AND THEREFORE USER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE WEBSITE AND/ OR APP MAY NOT BE APPROPRIATE FOR CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THIS WEBSITE AND/ OR APP.</p>
                <br />
                <p className="page-para">Your access to and use of the Services is subject to these Terms and Conditions, Privacy Policy and all applicable laws, rules, and regulations.</p> <br />
                <strong className="sub-header">
                3) ELECTRONIC COMMUNICATIONS
                </strong>
                <p className="page-para">When You use / avail the Services or send any data, information or communication to the Company, You agree and understand that You are communicating with the Company through electronic media/ records and You consent to receive communications via electronic records from the Company periodically and as and when required. the Company will communicate with You by email or any push or other message or electronic records on the email address and or mobile number available with the Company or made available to the Company through a Partner which will be deemed adequate service of notice / electronic record.</p>
                <br />
                <p className="page-para">You acknowledge that You provide specific consent to the Company or our respective affiliates and partners contacting you using the contact information you have provided to us at any time during your association with us for any purpose including the following purposes:</p>
                <br />
                <ul className="page-para">
                    <li className="page-para">To obtain feedback regarding the Service;</li>
                    <li className="page-para">To contact you for offering of new products or services, whether offered by us, or our respective affiliates or partners.</li>
                </ul>
                <br />
                <p className="page-para">You acknowledge and agree that you may be contacted for the above purposes, using any of the contact details provided by You, including via emails, text messages or phone calls.In the event You have indicated any 'do not disturb' preferences to Your telecom service provider, and registered Yourself on the national customer preference register / 'do not disturb' or similar list, We request You to ensure that Your registered preferences allow for communications to be sent to You in accordance with the above provisions.</p>
                <br />
                <p className="page-para">The Content contained in the Services including but not limited to on the Website and/ or Appis protected by copyright, trademark, patent, trade secret and other laws, and shall not be used except as provided in these Terms and Conditions, without the written permission of the Company.</p>
            </div>
            </React.Fragment>
        );
    }
}

export default TermsOfUse;