import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

const MobileShowPageLoader = props => {
    // Hardcoded values
    const rows = 3
    const columns = 2
    const coverHeight = 155
    const coverWidth = 120
    const padding = 20

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        <Container className="cat_page-content">
            <Row>
                <Col sm={12} md={9} lg={9}>
                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={200}
                        backgroundColor="#808080"
                        {...props}
                    >
                        <rect x="20" y="20" rx="5" ry="5" width="115" height="140" />
                        <rect x="150" y="30" rx="5" ry="5" width="30%" height="24" />
                        <rect x="150" y="70" rx="5" ry="5" width="50%" height="12" />
                        <rect x="150" y="100" rx="5" ry="5" width="50%" height="12" />
                        <rect x="150" y="120" rx="5" ry="5" width="50%" height="12" />
                    </ContentLoader>
                    {covers.map((g, i) => {
                        let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                        let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                        return (
                            <ContentLoader
                                speed={1}
                                backgroundOpacity={0.1}
                                foregroundOpacity={0.3}
                                width={"100%"}
                                height={100}
                                backgroundColor="#808080"
                                {...props}
                            >

                                <rect x="20" y="20" rx="5" ry="5" width="84" height="93" />
                                <rect x="155" y="20" rx="5" ry="5" width="60%" height="12" />
                                <rect x="155" y="40" rx="5" ry="5" width="50%" height="12" />
                                <rect x="155" y="60" rx="5" ry="5" width="40%" height="12" />
                            </ContentLoader>
                        )
                    })}
                </Col>


            </Row>
        </Container>
    )
}


export default MobileShowPageLoader