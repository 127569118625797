import React, { Component } from 'react';
import './Footer.css'

class PrivacyPolicy extends Component {
    state = {}
    render() {
        return (
        <React.Fragment>
            <div className="page-content container-fluid">
                    <h1 className="page-header" >Privacy Policy</h1>
                    <strong className="sub-header">1. APPLICATION</strong>
                    <p className="page-para">This Privacy Policy covers the information Samor Culture Labs Pvt. Ltd, a subsidiary of</p>
                    <p className="page-para">Agrahyah Technologies Private Limited, a company incorporated under the Companies Act, 1956, having its registered address at Shop No.2, Sej Plaza, Nr. Nutan Vidya Mandir School, Marve Road, Malad West Mumbai Mumbai City – 400064, Maharashtra, India (“Platform”, “We”, “Our”, “Company”), collects from its user (“User”,“You”) vide https://aawaz.com (“Website”) and any associated mobile sites, applications (“Apps”) and/or the Services provided by the Platform on the Website and/ or Apps (as defined in the Terms of Use of the Website - “Terms of Use”).</p>
                    <p className="page-para">
                    This Privacy Policy should be read in conjunction and together with the Terms of Use available on terms of use. Personal Information (defined here under) of a User is collected if the User registers with the Website and/ or App, accesses the Website and/ or App or takes any action on the Website and/ or App or uses or accesses the Services as defined in the Terms of Use. The terms ‘Personal Information’, ‘Sensitive Personal Data’ and ‘Information’ shall have the meaning ascribed to it under the applicable laws of India. Your use of Our services offered on the Website is constructed to mean that You consent to Our collection, storage, processing and transfer of Your Personal Information or Sensitive Personal Data or Information (collectively “Personal Information”) in accordance with this Privacy Policy and You consent to the terms and conditions of this Privacy Policy.
                    </p>
                    <strong className="sub-header">2. COLLECTION AND USE OF INFORMATION</strong>
                    <p className="page-para">The Personal Information which You may provide to the Platform and/or which We may collect as part of the information provided is or could be the following:</p>
                    <ul className="page-para">
                        <li>Your Registration Details which may include Your Name, Email Address, Mobile/Contact Details, Age, Gender, Password, Location, Pin Code, Area Code, Occupation, provided by You</li>
                        <li>Your Billing, Tax Registration, and other information pertaining to Your transactions on the Website and/ or the App or related to the Services</li>
                        <li>You may in some cases be required to share Your Debit Or Credit card details or other Bank Account details while accessing any services offered on the Website and/ or App on a subscription model</li>
                        <li>Your comments or other content that You publicly post or share on the Website and/ or App</li>
                        <li>If You access or engage with the Website and/ or App or Services associated with the Website and/ or App through a social media service, We may collect Your user ID or user name, any information You have permitted the social media service to share with us, and any information You have made public with the social media service</li>
                        <li>Your usage behavior of the Website and/ or App or interests, purchasing behavior and, other traceable activities; online, on apps and, on Your mobile such as destinations visited, viewed advertisements or content consumed</li>
                        <li>Details of the computer system or computer network which You use to visit the Website and undertake any activity on the Website, such as a unique device identifier including user behavior from web based cookies, session identifiers from device ID, commercially available services, data aggregator platforms</li>
                        <li>User preferences (e.g., preferred language settings)</li>
                        <li>IP address, operating system, browser type, browser version, browser configuration, name of internet service provider, and other types of computer and connection related information relevant to identifying Your type of device, connecting to the Website and/ or App, enabling data exchange with You and Your device, and ensuring a convenient use of the Website and/ or App</li>
                        <li>The Uniform Resource Locator (URL) and IP address of the website from which You accessed, or were directed to, Our Website, including date and time</li>
                        <li>Other pages visited while on Our Website, links followed on the Website, including date and time</li>
                        <li>The full URL click stream to, through and from the Website, including date and time</li>
                        <li>Service requests and orders placed</li>
                        <li>Transaction history, including open and completed transactions</li>
                        <li>Search terms entered</li>
                        <li>Services/products viewed or searched for on the Website and/ or App</li>
                        <li>Shopping cart and payment information</li>
                        <li>Credit card information</li>
                        <li>Information collected by cookies or similar technologies (as described in the next paragraph)</li>
                        <li>Newsletter subscriptions, enrollment for promotions, use of special offers, etc.</li>
                        <li>Consents, authorizations, etc. granted</li>
                        <li>Survey answers, reviews, ratings and other types of feedback provided</li>
                        <li>Content of any communication sent through the website, including any information posted in social communities on the Website or otherwise shared with the Platform and/or other users, as well as chat messages and chat transcripts</li>
                        <li>Information on software downloaded from the Website and/ or App; and</li>
                        <li>Any other information that You enter on or upload to the Website and/ or App (e.g., content that You fill into an online form, a photo that You upload).</li>
                    </ul>
                    <p className="page-para">Our primary purposes in collecting Personal Information from You is to allow You to use the Website and/ or App and various features and Services on or in relation to the Website and/ or App, to contact You for any Services provided by the Platform or its affiliates or its various service providers or Platform business partners and advertisers, to record Your Personal Information and details as permitted and required under applicable laws, statutory direction or judiciary orders; to serve various promotion materials and advertising materials to You, and such other uses as provided in the Terms of Use and this Privacy Policy.</p>
                    <p className="page-para">We may also use the Personal Information provided by You or collected by the Platform for content recommendations, transactional emails or to provide You with information including but not limited to direct marketing, online and offline advertising and other materials regarding products, services and other offers from time to time in connection with the Platform or its parent, subsidiary and affiliated companies (“Platform Entities“) and its joint ventures.</p>
                    <p className="page-para">We may also collect Your Personal Information to track User behavior and preferences for internal analytics of all Users of the Website and/ or App. We may also use Your Personal Information: (i) to evaluate Your eligibility for certain types of offers, products or services; (ii) to perform analytics and conduct customer research, including general market research or surveying Our customers’ needs and opinions on specific issues, generating sales and traffic patterns, and to analyze advertising effectiveness, both on an anonymous basis (e.g., by aggregating data) or on an individual basis (if legally permissible).</p>
                    <p className="page-para">The Platform may integrate certain third party software development kits (SDKs) within the Platform to assist in placing advertisements better suited to You and improve the overall user experience. Such third party SDK’s may directly collect certain Personal Information provided by/collected from You on the Platform, such as user behavior, preferences, account activity, IP address, User’s interactions with advertisements and identifiers for advertisers (IDFAs) and Google advertising ID (AAID).</p>
                    <p className="page-para">Such Personal Information is collected through the use of server log files and tracking technologies to collect and analyze certain types of technical information and may include cookies and web beacons. If You do not consent to the use of cookies and/or device session tracking by the Platform, You may at any time adjust Your browser settings and/or delete and/or disable the cookies. However, please note that should You choose to delete/ disable the use of cookies or other tracking technologies on Your computer, certain sections of the Website and/or Services provided by the Platform may not be available to You. We may combine Your Personal Information and any other information collected from tracking technologies and aggregate it with information collected from other Users using our Website and/ or App to attempt to provide You with the Services and a better experience on the Website and/ or App.</p>
                    <p className="page-para">You understand, agree and acknowledge that our collection, storage and processing of your Personal Information is for a lawful purpose connected with a function or activity of the Platform Entities and its joint ventures. You hereby consent to the collection, storage, use, disclosure, transfer of Your Personal Information by Platform for the purposes set out herein. You further understand, agree and acknowledge that Your Personal Information which is classified as Sensitive Personal Data or Information as per applicable laws is considered necessary for the Platform to provide various services on its Website and/ or App to You and for Your usage of the Website and/ or App and other services provided by Platform in relation to the Website and/ or App and You affirmatively consent to its collection, storage, transfer and processing.</p>
                    <strong className="sub-header">3. LINKS TO OTHER SITES / FINANCIAL DATA</strong>
                    <p className="page-para">We provide links to other websites and portals such as third party payment gateway providers for your convenience and information (“Other Websites”). For instance, the Platform will be using third party payment gateway providers to process and facilitate the payment of Your Subscription Fee to the Platform for providing You with access to the Subscription. Please note that the Platform does not directly collect any financial information such as Credit Card or Debit Card or Net Banking details from You. Such Other Websites are not controlled by us. When You visit such Other Website You do so at your own risk. These Other Websites may have their own privacy policies in place, which We recommend You review if You visit any such Other Websites. We do not assume any responsibility or liability for such Other Websites, the content of such Other Websites and their privacy practices, nor do We endorse them. We encourage You to familiarize Yourself with the privacy statements provided by such Other Websites prior to providing them with information about You or entering into any transactions on them.</p>
                    <p className="page-para">While transacting with these Other Websites, You may provide Your financial information including without limitation Your bank account details, credit card account details or your details pertaining to any payment settlement or pre-paid instrument service provider (“Financial Information”). You understand, agree and acknowledge that the Website and/ or App and Platform never receives Your Financial Information from these Other Websites. Your Personal Information will be dealt with by these Other Websites in accordance with their respective privacy policies and other terms and conditions and the Platform shall not be liable, accountable or responsible for Your Personal Information and Financial information which You provide to these Other Websites.</p>
                    <p className="page-para">Identity theft and the practice currently known as “phishing” are of great concern to the Platform. Safeguarding information to help protect You from identity theft is a top priority. We do not and will not, at any time, request Your credit card information or national identification numbers in a non-secure or unsolicited e-mail or telephone communication.</p>
                    <strong className="sub-header">4. DISCLOSURE</strong>
                    <p className="page-para">We may share Personal Information with the following parties:</p>
                    <ol className="page-para">
                        <li>When You agree to have Your Personal Information shared: While on our Website and/ or App, You may have the opportunity to opt-in to receive information and/or marketing offers from a third party or to otherwise consent to the sharing of Your Personal Information with a third party. If You agree to have Your Personal Information shared, Your Personal Information will be disclosed to the third party and the Personal Information You disclose will be subject to the privacy policy and business practices of that third party.</li>
                        <li>Third Parties Providing Services on Our Behalf: We may from time to time employ third party service providers to perform functions on Our behalf, such as hosting or operating Our site, hosting personal information, executing interactive voice response programs, sending e-mail message communications, and providing data analysis services, within or outside India. We shall endeavor that the third parties and agents engaged by us to whom We may provide access to the Personal Information provided by You for the purposes set out herein are under an contractual obligation to protect the Personal Information provided by You in accordance with the same standard as the Platform has agreed to be subject to in this Privacy Policy.</li>
                        <li>Affiliate Sharing and Business Transfers: We may share your Personal Information with other entities affiliated with us for internal reasons, primarily for business and operational purposes. Subject to Your explicit consent to receiving marketing materials from companies affiliated with us, We may share Your e-mail address and information on Your preferences and interests with such affiliated companies in order for them to provide You with certain marketing and promotional communication. In the event that the Company or any of its assets, including the Website and/ or App, may be acquired/merged/de-merged/spun-off and/ or any other transactions may occur in which Your Personal Information is one of the business assets of the transaction, Your Personal Information may be transferred for the purpose of such transactions. Recipients of Your Personal Information may be located in any country. This may include countries where applicable data protection laws provide a lesser degree of protection than Your home country.</li>
                        <li>Legal Protections and Other Disclosures: We may transfer and disclose information, including your Personal Information, usage information and Device Identifier (including IP address), to third parties: (i) to comply with a legal obligation; (ii) when We believe in good faith that the law requires it; (iii) at the request of governmental authorities conducting an investigation; (iv) to verify or enforce the Website’s Terms of Use or other applicable policies; (v) to respond to an emergency; or otherwise (vi) to protect the rights, property, safety, or security of third parties, Users or the public. Pursuant to valid requests by law enforcement agencies, We may also use Device Identifiers to identify users, and may do so in cooperation with copyright owners, Internet service providers, wireless service providers or law enforcement agencies, in our discretion. Such disclosures may be carried out without any notice to You.</li>
                    </ol>
                    <strong className="sub-header">5. PRIVACY</strong>
                    <p className="page-para">The Company respects your privacy and is committed to protecting the personally identifiable information that You provide, in connection with Your use of its Website and/ or App. The Company takes reasonable and appropriate precautions to protect Your personally identifiable information from unauthorized disclosure and to prevent any possible security breaches.</p>
                    <p className="page-para">We understand the importance of data security and We want your user experience with us to be as safe as possible. We have implemented reasonable safeguards and precautions to protect Personal Information provided by You, including technical and organizational measures against unauthorized access, improper use, alteration, unlawful or accidental destruction, and accidental loss, both in an online and offline context. We have adopted reasonable and best security practices and procedure and We maintain technical and organizational security measures and procedures to protect the security of the Personal Information provided by you, which are consistent with measures used by other similar websites.</p>
                    <p className="page-para">Please understand that, while We strive to protect the Personal Information provided by You against potential risks and exposures, there is no absolute security in the online sphere. Hence, We ask You to support our IT security efforts by not disclosing any Personal Information on our website that is particularly sensitive or not required in the specific context. Furthermore, You can help us protecting your personal data by signing off when You finish using login-protected areas of the website.</p>
                    <p className="page-para">You understand that part of the services offered via the Website and/ or App is providing You with information about the content offered via the Website and/ or App. By providing Your email address in the registration process You consent to the receipt of such content information via email. You may opt out of receiving e-mail newsletters by following the opt-out instructions provided to You in the e-mail communication. Please note that We reserve the right to send certain communications relating to Your Account or use of the website in order to perform the requested services for You, to You. These transactional account messages will not be stopped even after You choose to opt-out from marketing communications. In accordance with our routine record keeping, We may delete certain records that contain Your Personal Information that You have submitted through the Website and/ or App. The Company is under no obligation to store such Personal Information indefinitely and hereby expressly disclaim any liability arising out of, or related to, the destruction of such Personal Information. In addition, You should be aware that it may not be possible to completely remove or delete all of your Personal Information from our databases in order to effectuate any opt-out preferences You have made due to technical concerns with data backup and contingency support.</p>
                    <p className="page-para">The Company incorporates commercially reasonable safeguards to help protect and secure the Personal Information provided by You. However, in light of the fact that electronic data can be intercepted, corrupted, lost or destroyed, please note that the Company cannot ensure or warrant the security of any data, and your use of the Website and/ or App is at your own risk.</p>
                    <p className="page-para">If You wish to have the Personal Information that You have provided to us deleted, You can always do so by sending a request to us on the contact information provided in Clause 8. You may note that deletion of certain Personal Information may lead to cancellation of Your registration with the Website and/ or App and/or Your access to certain features of the Website and/ or App can be restricted and may also lead to complete cessation of Your access to the Website and/ or App.</p>
                    <strong className="sub-header">6. SECURITY</strong>
                    <p className="page-para">The Platform uses commercially reasonable security practices and procedures industry practices and procedures designed to help keep the Personal Information provided by you, safe. Secure server software (SSL) encrypts all Personal Information You submit before it is sent to the Platform. Furthermore, all of the User data We collect is protected against unauthorized access. To prevent unauthorized access, maintain data accuracy, and ensure correct use of information, We will employ commercially reasonable and practicable security practices and procedures and security methods and technologies. We will also ensure on reasonable commercial efforts basis that any agent or third party that We employ complies with the same security standards as We do for the protection of the Personal Information provided by you.</p>
                    <p className="page-para">Your Personal Information may be transferred to or may be maintained on computers, computer systems and computer resources located outside of Your state or country where the privacy laws may be different (but no less stringent than the restrictions imposed by Indian law) as those where You live. If You are located outside India and choose to provide information to us, please be aware that the Site is hosted in India and that Platform keeps or transfers information to and from India for storage and processing. Your submission of such information represents Your agreement to that transfer.</p>
                    <p className="page-para">Unfortunately, the transmission of information over the Internet is not completely secure. Although We strive to protect Your personal data, We cannot guarantee the security of Your data while it is being transmitted to our Website and/ or App; any transmission is at Your own risk. Once We have received Your information, We have commercially reasonable procedures and security practices in place to reasonably endeavor to prevent unauthorized access.</p>
                    <strong className="sub-header">7. NOTIFICATION OF CHANGES AND YOUR ACCEPTANCE OF THE PRIVACY POLICY</strong>
                    <p className="page-para">From time to time We may update this Privacy Policy. Your continued use of Our Website and/ or App and Our Services constitutes Your acceptance of the then-current Privacy Policy and Terms of Use so We encourage You to visit this page periodically to review any changes.</p>
                    <strong className="sub-header">8. CONTACT US</strong>
                    <p className="page-para">If You have any questions about this Privacy Policy, the practices of Platform or Your dealings with the Website and/ or App, You can email us at hello@aawaz.com or You can write to us: S-2, Sej Plaza, Marve Road, Malad West, Mumbai.</p>
                    <strong className="sub-header">9. GRIEVANCE OFFICER</strong>
                    <p className="page-para">[Mr. Vasa] shall be the Grievance Officer for the purpose of this Privacy Policy. If You have any questions or grievance or want to delete or modify Your Personal Information, You shall mark Your correspondence in the name of Mr. Vasa, Grievance Officer in accordance with Clause 8. Any grievance that we receive from You shall be resolved within a period of one month from the date of receipt of the grievance from You. Any communication which is not marked as such shall not be treated as a valid and binding communication for the purpose of this Privacy Policy and applicable laws with respect to this Privacy Policy.</p>
            </div>
        </React.Fragment>
        );
    }
}

export default PrivacyPolicy;