import React, { useEffect, useState } from 'react'
import axios from 'axios';
import auth from '../../../../services/authentication'
import { Link, useHistory } from 'react-router-dom'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import SidebarLoader from '../../../HomeSection/HomeComponent/Loader/SidebarLoader'

function CategoriesTab() {
    const [categories, setcategories] = useState([])
    const [Loader, setLoader] = useState(true)
    let history = useHistory()
    useEffect(() => {
        axios.get(`https://prod.aawaz.com/api/category/`, { auth: auth })
            .then(res => {
                setcategories(res.data);
                setLoader(false)
            })
    }, [])
    const ClickedCat = (data) => {
        console.log(data)
        history.push(`/category/${data}`)
    }
    return (
        <div className="category-sec">
            {Loader ? <SidebarLoader />
                :
                <ul className="tab-container" style={{ marginBottom: "70px" }}>
                    {categories.map(category =>
                        <Menu iconShape="circle" key={category.uniqueSlug}>
                            <MenuItem
                                icon={<img src={category.iconImageUrl} onClick={() => ClickedCat(category.shortUrl)} className="sidebar-icons" />}
                            >
                                <Link to={`/category/${category.shortUrl}`} data-toggle="tooltip" data-placement="bottom" title={category.name}>{category.name}</Link>

                            </MenuItem>
                            {/* <p>{category.iconImageUrl}</p> */}
                        </Menu>
                    )}
                </ul>
            }
        </div>
    )
}

export default CategoriesTab
