import React from 'react';
import { Navbar, Tab, Tabs, Nav, Dropdown, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../../../img/logo.png';
// import { Nav, Navbar, Col, Dropdown, ToggleButtonGroup, Button } from 'react-bootstrap'
import userLogin from '../../../img/user-profile.svg'
import '../../../css/pages.css';
import { withRouter } from 'react-router-dom';
import LiveBtn from '../../../img/live-g-icon.gif';
import languageIcon from '../../../img/icons/language-icon.svg'
import languageActiveIcon from '../../../img/icons/language-active.svg'
import SearchIcon from './ic_nav_search.svg'
import ProfileIcon from './ic_nav_profile.svg'
import ActiveProfileIcon from './ic_nav_profile_active.svg'
import ActiveSearchIcon from './ic_nav_search_active.svg'
import firebase from 'firebase/app';
import { YoloProvider } from 'react-googleyolo'

const HeaderBrand = (props) => {

    let pushTo = (tab) => {
        switch (tab) {
            case 'Category':
                // props.history.push('/nav-tab/0')
                // props.history.push(`/Search`)
                break;
            case 'Channel':
                // props.history.push(`/nav-tab/1`)
                props.history.push(`/Search`)
                break;
            case 'Live':
                // props.history.push(`/nav-tab/2`)
                props.history.push(`/Profile`)
                break;
            default:
                props.history.push('/')
        }
    }
    // pushTo(e.target.name)
    return (
        <div className="mobile-only header-nav">
            <Navbar bg="white" variant="white" className="sticky-navbar mobile-only">
                {/* <React.Fragment> */}
                <Nav
                    className="mobile-only nav"
                    activeKey="/"
                    exact="true"
                    onSelect={selectedKey => alert(`selected ${selectedKey}`)}
                    style={{ padding: "10px 0px" }}
                >
                    <Row style={{ width: "100%" }}>
                        <Col xs={7}>
                            <Nav.Item>

                                <NavLink to="/" exact={true} >
                                    <img src={window.location.pathname === "/" ? Logo : Logo} alt="Home" width="60" />
                                </NavLink>
                            </Nav.Item>
                        </Col>
                        <div className="mobi-right-bar">
                            <Col xs={3}>
                                <Nav.Item>
                                    <NavLink to="/Search" exact={true} >
                                        <img src={window.location.pathname === "/Search" ? ActiveSearchIcon : SearchIcon} alt="Home" />
                                    </NavLink>
                                    {/* <NavLink to="/nav-tab/2" exact={true} > */}
                                    <span data-toggle="tooltip" data-placement="bottom" title="Select your Language" onClick={() => props.userLangPrefBottomPrompt()} style={{ cursor: "pointer" }} > <img src={props.defaultUserLangPref ? (props.defaultUserLangPref.length >= 1) && props.defaultUserLangPref.length < 4 ? languageActiveIcon : languageIcon : languageIcon} alt="img" /></span>
                                    {/* </NavLink> */}
                                    {/* <NavLink to="/Profile" exact={true} >
                                        <img src={window.location.pathname === "/Profile" ? ActiveProfileIcon : ProfileIcon} alt="Profile" />
                                    </NavLink> */}
                                </Nav.Item>
                            </Col>
                        </div>
                    </Row>
                </Nav>
                {/* </React.Fragment> */}
            </Navbar>
        </div>
    );
}

export default withRouter(HeaderBrand);