import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

const MobileLiveLoader = props => {
    // Hardcoded values
    const rows = 3
    const columns = 2
    const coverHeight = 205
    const coverWidth = 400
    const padding = 25

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        // <div style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
        <Container>
            {/* <ContentLoader
                className="page-title"
                speed={1}
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={70}
                // foregroundColor="#808080"
                backgroundColor="#808080"
                // baseUrl={"/"}
                {...props}
            >
                <rect
                    x="0"
                    y="0"
                    rx="10"
                    ry="10"
                    width={"25%"}
                    height="20"
                />


            </ContentLoader> */}
            <Row>

                <Col >

                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={rows * coverHeightWithPadding}
                        // foregroundColor="#808080"
                        backgroundColor="#808080"
                        // baseUrl={window.location.pathname === "/" ? "/" : window.location.pathname === "/channels" ? "/channels" : "/category/:id"}
                        {...props}
                    >
                        {covers.map((g, i) => {
                            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                            return (
                                <rect
                                    key={i}
                                    x={vx}
                                    y={vy}
                                    rx="10"
                                    ry="10"
                                    width={coverWidth}
                                    height={coverHeight}
                                />
                            )
                        })}
                    </ContentLoader>
                </Col>

            </Row>
        </Container>
        // </div>
    )
}


export default MobileLiveLoader