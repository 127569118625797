import React, { useEffect, useRef } from 'react';
import "./LandingPage.css";
import "../../../index.css";

import beingCeoLogo from "./img/beingCeoLogo.png";
import BannerImg from "./img/BannerImg.png";
import ListenBtn from "./img/listenbtn.png";
import Raymond from "./img/ray.png";
import Twitter from "./img/twitter.png";
import Jagran from "./img/jag.png";
import Vip from "./img/vip.png";
import LoadStar from "./img/lod.png";
import Angel from "./img/angel.png";
import Author from "./img/Author.png";
import BeingCEOEpSanjay from "./img/Being-CEO-Ep-Sanjay.jpg";
import BeingCEOEpManish from "./img/Being-CEO-Ep-Manish.jpg";
import BeingCEOEpApurva from "./img/Being-CEO-Ep-Apurva.jpg";
import DeepaliHost from "./img/deepali.png";

import PlayBtn from "./img/play-btn.png";
import EpiPlay from "./img/epi-play.png";
import EpiPause from './img/pause.png'
import InfoBtn from "./img/info-btn.png";
import ReasonBorder from './img/reason-border.png';
import ReasonBorder1 from './img/reason-border1.png';
import HostT from "./img/twit.png";
import HostLI from "./img/li.png";
import DarkLogo from "./img/dark-logo.png";
import firebase from 'firebase';
import { selectPlayPause } from '../../../features/playerSlice'
import { useSelector } from 'react-redux'
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap'

const BeingCEO = (props) => {
    const random = [Math.floor(Math.random() * 10)]
    const random2 = [Math.floor(Math.random() * 20)]

    const playerPlay = useSelector(selectPlayPause);
    const pauseClicked = (index) => {
        props.addPlayerShowData(props.data, index)
        props.pauseFromPlaylist()
    }
    const playClicked = async (index, uniqueSlug) => {
        await props.addPlayerShowData(props.data, index)
        await props.handlePlaylist(index, uniqueSlug)
    }
    const OnloadFunc = async () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                props.getShowData('being-ceo-with-deepali-naair')

            }

        }
        )
    }
    useEffect(() => {
        OnloadFunc()
    }, [])
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4glaon8', 'template_m6g846p', form.current, 'user_K6WapEtfMMsleufMjprc3')
            .then((result) => {
                toast.success("Email sent successfully !!")
            }, (error) => {
                toast.error("Email sent failed !! try again later")
                console.log(error.text);
            });
    };
    return (
        <React.Fragment>
            <ToastContainer position="top-center" draggable />
            <section id="lpheader">
                <div className="container">
                    <header className="d-flex flex-wrap justify-content-left py-3 mb-4">
                        <a href="/" className="d-flex align-items-left mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                            <img src={beingCeoLogo} className="lp-logo" />
                        </a>
                    </header>
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="para-bold banner-heading">
                                CEOs <br />
                                #Unfiltered
                            </h1>
                            <p className="banner-para para-reg">What do they know <br />
                                That you don't?</p>
                            <img src={ListenBtn} alt="" className="banner-btn" onClick={() => props.addPlayerShowData(props.data, 0)} />
                        </div>
                        <div className="col-lg-6">
                            <img src={BannerImg} className="banner-img" />
                        </div>
                    </div>

                </div>
            </section>
            <section id="leaders" className="content">
                <h4 className="title-orange">Hear from Leaders of India Inc.</h4>

                <div className="client-img">
                    <img src={Raymond} />
                    <img src={Twitter} />
                    <img src={Jagran} />
                    <img src={Vip} />
                    <img src={LoadStar} />
                    <img src={Angel} />
                </div>
            </section>
            <section id="quote" className="content">
                <h4 className="title-orange">CEO Quote of the week</h4>
                <p className="sub-title">"Surround yourself with people who are <br /> better than you."</p>
                <img src={Author} className="ppl-img" />
                <p className="lpauthor-name">
                    - Alok Ranjan
                </p>
                <p className="author-bio">
                    CEO, Flipcarbon integrated Solutions Pvt. Ltd.
                </p>
            </section >
            <section id="player" className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-5 ">
                            <h3 className="episode-head-title">Featured Episodes</h3>
                            <div className="feature-episode-section">
                                {/* Sanjay */}
                                {props.data.length !== 0 ? (props.data.episodes.slice(0, 3)).map((data, index) =>
                                    <div className="row fepisode-list">
                                        <div className="col-lg-4">
                                            <div className="episode-img vid-clip-sm">
                                                <img src={data.featuredImageUrl} alt={data.title} />

                                                <div className="vid-clip-play" >
                                                    {/* <img src={PlayBtn} /> */}
                                                    {index === props.index && props.playerRef.current && props.playerRef.current.audio.current.play && playerPlay === true && props.CurrentAudiofile === data.audioFileUrl ?
                                                        <p style={{ display: index === props.index ? 'inline-block !important' : 'none' }}><img src={EpiPause} onClick={() => pauseClicked(index)} /></p> :
                                                        <p className="desktop-epi-pause">  <img src={PlayBtn} onClick={() => playClicked(index, data.uniqueSlug)} /></p>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-7 episode-content">
                                            <h5 className="episode-name">{data.title}</h5>
                                            <p className="episode-description">{index === 0 ? "Former CEO, Raymond" : index === 1 ? "MD, Twitter India" : "President, Jagran Group"}</p>
                                            <span class="dropdownLanding-container-feature" tabindex="-1">
                                                <span className="more-link">more</span>
                                                <div class="dropdownLandingFeature">
                                                    <div style={{ width: "100%" }}>  {data.shortDescription}</div>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                )
                                    : null
                                }
                            </div>
                        </div>
                        <div className="col-7">
                            <h3 className="episode-head-title2">All Episodes</h3>
                            <div className="episode-section">
                                {/* episode list */}
                                {props.data.length !== 0 ? props.data.episodes.map((data, index) =>
                                    <div className="row episode-list">

                                        <div className="col-lg-2">
                                            <img src={data.featuredImageUrl} className="epi-img" />
                                        </div>

                                        <div className="col-lg-7">
                                            <h5 className="episode-name" data-toggle="tooltip" data-placement="bottom" title={data.title}>{data.title}</h5>
                                            <p className="episode-description" data-toggle="tooltip" data-placement="bottom" title={data.shortDescription}>{data.shortDescription}</p>

                                        </div>
                                        <div className="col-lg-1">
                                            {index === props.index && props.playerRef.current && props.playerRef.current.audio.current.play && playerPlay === true && props.CurrentAudiofile === data.audioFileUrl ?
                                                <p style={{ display: index === props.index ? 'inline-block !important' : 'none' }}><img src={EpiPause} className="epi-play-icon" onClick={() => pauseClicked(index)} /></p> :
                                                <p className="desktop-epi-pause"> <img src={EpiPlay} className="epi-play-icon" onClick={() => playClicked(index, data.uniqueSlug)} /></p>}
                                        </div>
                                        <div className="col-lg-1">


                                            <span class="dropdownLanding-container" tabindex="-1">
                                                <img src={InfoBtn} className="info-icon" />
                                                {/* <div class="three-dots"></div> */}
                                                <div class="dropdownLanding">
                                                    <div style={{ width: "100%" }}>  {data.shortDescription}</div>
                                                </div>
                                            </span>

                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="reason" className="content">
                <h4 className="title-orange center reason-header">Reasons to Listen <span> Being CEO Podcast</span> </h4>
                <img src={ReasonBorder1} className="reason-border" />
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <h3 className="reason-no-point">
                                1
                            </h3>
                            <h4 className="reason-head2">Get a peek into <br /> the CEO brain</h4>
                            <p className="reason-description">
                                Our guests share strategic career
                                advice & talk about their core values,
                                to inspire others aspiring to get
                                ahead in their career
                            </p>
                        </div>
                        <div className="col-3">
                            <h3 className="reason-no-point">
                                2
                            </h3>
                            <h4 className="reason-head2">Fuel your Ambition</h4>
                            <p className="reason-description">
                                Feel stuck in your current gig? <br />
                                Dream of bigger challenges? <br />
                                Our podcast can be just the
                                pick-me-up you need! Hear from
                                business leaders who overcame
                                extraordinary odds, and worked hard
                                to get to where they are today!
                            </p>
                        </div>
                        <div className="col-3">
                            <h3 className="reason-no-point">
                                3
                            </h3>
                            <h4 className="reason-head2">Management Lessons
                                Ver 2.0</h4>
                            <p className="reason-description">
                                There’s a lot they don’t teach you in
                                B-School. Learn about goal setting,
                                leadership, conflict management,
                                work life balance and more through
                                real-life anecdotes that go beyond
                                in-classroom theories
                            </p>
                        </div>
                        <div className="col-3">
                            <h3 className="reason-no-point">
                                4
                            </h3>
                            <h4 className="reason-head2">CEOs get Candid </h4>
                            <p className="reason-description">
                                Our host Deepali Naair is
                                known to put her guests at
                                ease, so they open up and
                                share a side of themselves few
                                people are privy to. India’s
                                biggest bosses go off-script
                                on this show.


                            </p>
                        </div>
                    </div>
                </div>

            </section>
            <section id="host">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h4 className="host-title">Meet Our Host</h4>
                            <h2 className="host-name">
                                Deepali Naair
                            </h2>
                            <p className="social-host">
                                <img src={HostLI} className="host-l" />
                                <img src={HostT} className="host-t" />
                            </p>
                            <img src={DeepaliHost} className="host-img" />
                        </div>
                        <div className="col-6">
                            <p className="host-description">
                                Deepali Naair hosts BeingCEO in her inimitable style and gets India’s biggest leaders to share a side of themselves we seldom see. She is known for her impeccable research and having served in leadership roles at some of the best known names in business, her conversations with guests are thought provoking, while brimming with unmistakable, easy camaraderie.  <br /> <br />
                                When she is not indulging in her love for podcasting, she serves as the CMO of IBM driving all marketing and brand initiatives and customer relations for the India & South Asia region. Deepali Naair is known for mentoring upcoming leaders at women led startups and premium B Schools. She is a much sought after speaker on leadership, marketing, culture and digital transformation and has been a part of elite juries for various advertising awards. <br />
                                Most recently, she received the CMO of the year award by The Economic Times being recognised as one of India’s Most Promising Tech Marketers, and frequently makes it to India’s Most Influential Indian Women lists.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about">
                <div className="container">
                    <h4 className="title-orange">About aawaz.com</h4>
                    <p className="lp-about-para">
                        aawaz.com is the <span className="strong-txt">largest</span> spoken-word audio and podcast network offering content in four Indian languages.
                        Live since January 2019, aawaz contains over <span className="strong-txt">1200 hours</span> of high-quality original content in <span className="strong-txt">English, Hindi,
                            Marathi and Urdu,</span> spanning 24 genres, 350+ shows and 8500+ episodes, with <span className="strong-txt">10,00,000 listeners</span> and
                        <span className="strong-txt"> 6 million+</span> streams every month.
                    </p>
                </div>
            </section>
            <section id="contact" className="content">
                <div className="container">
                    <h4 className="header-contact">
                        What can we do better?
                    </h4>
                    <form ref={form} onSubmit={sendEmail} className="contact-form">
                        <div className="row">
                            <div className="col-6">
                                <input type="text" placeholder="Your Name" name="name" required />
                            </div>
                            <div className="col-6">
                                <input type="text" placeholder="Your Email id" name="email" required />
                            </div>
                            <div className="col-12">

                                <input type="text" placeholder="Comments / Suggestions" name="message" required />
                            </div>
                        </div>

                        <button type="submit" className="submit-btn">TELL US!</button>
                    </form>
                </div>
            </section>
            <section className="content" id="footer">
                <img src={DarkLogo} className="footer-dark-logo" />
            </section>
        </React.Fragment>
    )
}
export default BeingCEO