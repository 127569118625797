import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { Nav, Navbar, Col, Dropdown, ToggleButtonGroup, Button } from 'react-bootstrap'

class footerSocial extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-6 socio-sec">
                        <ul className="footer-menu">
                            <NavLink exact activeClassName="activeheader" to="/about-us"><span className="headerHover">About</span></NavLink>
                            <NavLink exact activeClassName="activeheader" to="/terms-of-use"><span className="headerHover">Terms of use</span></NavLink>
                            <NavLink exact activeClassName="activeheader" to="/privacy-policy"><span className="headerHover">Privacy Policy</span></NavLink>
                            <NavLink exact activeClassName="activeheader" to="/disclaimer"><span className="headerHover">Disclaimer</span></NavLink>
                            <NavLink exact activeClassName="activeheader" to="/faq"><span className="headerHover">FAQ</span></NavLink>
                            <a href="https://www.aawaz.com/media/" target="_blank" rel="noopener noreferrer">
                                <li>Media</li></a>
                            <a href="https://business.aawaz.com/" target="_blank" rel="noopener noreferrer">
                                <li>Business</li></a>
                            <NavLink exact activeClassName="activeheader" to="/contact"><span className="headerHover">Contact</span></NavLink>

                        </ul>
                    </div>
                    <div className="col-lg-6 copyright-sec">
                        <ul className="footer-menu">
                            <li>
                                Copyright © 2021 <span className="brand-name">Aawaz</span>. All Rights Reserved.
                            </li>
                        </ul>
                    </div>
                </div>
              
            </React.Fragment>
        );
    }
}

export default footerSocial;