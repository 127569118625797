import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

class ScrolltoTop extends Component {
    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return <h1></h1>;
    }
}

export default withRouter(ScrolltoTop);