import http from '../http.common';
import auth from '../../../../services/authentication';

const baseURL = "https://prod.aawaz.com";

const getAllShows = () => {
    return http.get(`${baseURL}/web/live_shows/v2/`);
};

const getShowsByUniqslug = (data) => {
    return http.get(`${baseURL}/live_shows/shows/${data}`);
};

const randomName = () => {
    return http.get(`${baseURL}/live_shows/random_name/`);
}
const CheckLive = () => {
    return http.get(`${baseURL}/live_shows/channel_list/`);
}

const BroLive = () => {
    return http.get(`${baseURL}/web/dashboard-v2/sec-511b87`, { auth: auth });
}

export {
    getAllShows, getShowsByUniqslug, randomName, CheckLive, BroLive
}