import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

const MobileEpisodeLoader = props => {
    // Hardcoded values
    const rows = 3
    const columns = 2
    const coverHeight = 155
    const coverWidth = 120
    const padding = 20

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        <Container className="cat_page-content">
            <Row>
                <Col sm={12} md={9} lg={9}>
                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={500}
                        backgroundColor="#808080"
                        {...props}
                    >
                        <rect x="60" y="30" rx="5" ry="5" width="215" height="240" />
                        <rect x="90" y="300" rx="5" ry="5" width="50%" height="24" />
                        <rect x="30" y="340" rx="5" ry="5" width="90%" height="12" />
                        <rect x="30" y="360" rx="5" ry="5" width="90%" height="12" />
                        <rect x="30" y="380" rx="5" ry="5" width="90%" height="12" />
                    </ContentLoader>
                </Col>
            </Row>
        </Container>
    )
}


export default MobileEpisodeLoader