import React, { Component } from 'react';
import ReactBootstrap, { Jumbotron, Navbar, Nav, Button, Col, Grid, Panel, FormGroup, Card, div, Row } from 'react-bootstrap';
import './Footer.css'

class Faq extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="page-content container-fluid">
                    <h1 className="page-header">FAQ</h1>
                    <Row>
                        <Col lg={6} md={12} className="faq-rline">
                            <strong className="panel-title">
                                आवाज़ क्या है?
                            </strong>
                            <p className="page-para">
                                <a href="https://www.aawaz.com">Aawaz.com</a> भारत पहला ऐसा ऑडियो प्लेटफॉर्म (ऐप और वेबसाइट) है, जहां आप अपनी पसंद के और अपनी मांग के अनुसार 100% बिल्कुल नए और उम्दा शो सुन सकते हैं। यह सभी शो हिन्दी में उपलब्ध होंगे।
                            </p>
                            <br />
                            <strong className="panel-title">
                                कैसे सम्पर्क करें?
                            </strong>
                            <p className="page-para">
                                विज्ञापन, सामग्री बनाने सबंधित प्रश्न या फिर प्लेटफॉर्म में त्रुटि से संबधित सवालों के लिए आप हमें <a href="mailto:hello@aawaz.com">hello@aawaz.com</a> पर लिख सकते हैं।
                            </p>
                            <br />
                            <strong className="panel-title">
                                aawaz ऐप मुफ्त है या पैसा देना होगा?
                            </strong>
                            <p className="page-para">
                                <a href="https://www.aawaz.com">Aawaz.com</a> वेब पर निःशुल्क उपलब्ध है। आप अपने एंड्रॉयड फोन पर भी इस ऐप को गूगल प्ले स्टोर में जाकर डाउनलोड कर सकते हैं।
                            </p>
                            <br />
                            <strong className="panel-title">
                                प्रतिक्रिया आप तक कैसे पहुंचाएं?
                            </strong>
                            <p className="page-para">
                                <a href="https://www.aawaz.com">Aawaz.com</a> के बारे में आपकी प्रतिक्रिया हम ज़रुर जानना चाहेंगे। आप हमें <a href="mailto:hello@aawaz.com">hello@aawaz.com</a> पर लिख सकते हैं या फिर आप अपनी राय हमें यहां दिए फार्म पर भी लिख कर बता सकते हैं। <a href="http://bit.ly/aawazfdb" target="_blank">http://bit.ly/aawazfdb</a>
                            </p>
                            <br />
                            <strong className="panel-title">
                                लॉगिन कैसे करें?
                            </strong>
                            <p className="page-para">
                                आपके ऐप पेज पर सबसे नीचे लिखे प्रोफाइल का चयन करें। यहां पर आपको लॉगिन करने का विकल्प मिलेगा। उपलब्ध सामग्री अपने एंड्रॉयड पर डाउनलोड करने के लिए ज़रुरी है कि आप लॉगिन करें।
                            </p>
                            <br />
                            <strong className="panel-title">
                                मैं लॉगिन क्यों नहीं कर पा रहा?
                            </strong>
                            <p className="page-para">
                                इसके पीछे कई कारण हो सकते हैं।
                                <ol>
                                    <li>रजिस्ट्रेशन के लिए आपने जिस इ मेल आई डी और पासवर्ड का उपयोग किया है उसकी एक बार जांच कर लें</li>
                                    <li>आपने रजिस्ट्रेशन के लिए जिस फोन नम्बर का उपयोग किया है उसे जांच लें</li>
                                </ol>
                            </p>
                            <br />
                            <strong className="panel-title">
                                मैंं aawaz.com या ऐप पर कैसे रजिस्टर करुं
                            </strong>
                            <p className="page-para">
                                ऐप पेज पर सबसे नीचे लिखे "प्रोफाइल" को सिलेक्ट करें. यहां आपको 'साइन अप' का ऑप्शन मिलेगा।
                            </p>
                            <br />
                            <strong className="panel-title">
                                क्यों वेबसाइट या मोबाइल साइट पर डाउनलोड नहीं हो रहा?
                            </strong>
                            <p className="page-para">
                                अगर आप <a href="http://aawaz.com">aawaz.com</a> का उपयोग गूगल क्रोम, सफारी या फिर मोज़िला फायरफॉक्स जैसे वेब ब्राउज़र पर रहे हैं, तो आप यहां उपलब्ध सामग्री डाउनलोड नहीं कर पाएंगे। आपको इसके लिए aawaz.com का <a href="https://play.google.com/store/apps/details?id=com.aawaz&hl=en_IN&gl=US">एंड्रॉयड ऐप</a> ही इस्तेमाल करना होगा।
                            </p>
                        </Col>
                        <Col lg={6} md={12}>
                            <br />
                            <strong className="panel-title">
                                पूरी सीरीज़ को कैसे डाउनलोड किया जाए?
                            </strong>
                            <p className="page-para">
                                आपके ऐप पर उपलब्ध पूरी सीरीज़ को डाउनलोड करने के लिए अपने पसंदीदा शो पर क्लिक करें। यहां पर सबसे ऊपर मौजूद 'डाउनलोड ऑल' विकल्प पर क्लिक करें।
                            </p>
                            <br />
                            <strong className="panel-title">
                                शो कैसे डाउनलोड किया जा सकता है?
                            </strong>
                            <p className="page-para">
                                सिर्फ एक एपिसोड को डाउनलोड करने के लिए आप आपने पसंदीदा शो पर क्लिक करें। यहां हर एपिसोड के साथ डाउनलोड का बटन है, जहां आप क्लिक कर उस एपिसोड को डाउनलोड कर सकते हैं।
                            </p>
                            <br />
                            <strong className="panel-title">
                                शो प्ले/डाउनलोड नहीं हो रहा?
                            </strong>
                            <p className="page-para">
                                क्या आपका इंटरनेट सही तरीके से काम कर रहा है? किसी और नेटवर्क का उपयोग कर फिर से कोशिश करें। फिर भी अगर किसी तरह ही समस्या है तो cache को साफ कर फिर से कोशिश करें।</p>
                            <br />
                            <strong className="panel-title">
                                मुझे शो सही तरीके से सुनाई नहीं दे रहें?
                            </strong>
                            <p className="page-para">
                                कृप्या कर अपने फोन या फिर कंप्यूटर के वॉल्यूम सेटिंग की जांच कर लें। अपने हेडफोन या फिर ब्लूटूथ को भी एक बार जांच लें।</p>
                            <br />
                            <strong className="panel-title">
                                क्या यह ऐप सभी मोबाइल पर सुनी जा सकती हैं?
                            </strong>
                            <p className="page-para">
                                <a href="http://www.aawaz.com">www.aawaz.com</a>  क्रोम, सफारी और फायर फॉक्स जैसे सभी प्रचलित वेब ब्राउज़र पर उपलब्ध है। एंड्रॉयड फोन पर इस ऐप को प्लेस्टोर में जाकर भी डाउनलोड किया जा सकता है। <a href="http://bit.ly/2FrVEz4">डाउनलोड</a>
                            </p>
                            <br />
                            <strong className="panel-title">
                                क्या यह सिर्फ एंड्रॉयड फोन उपयोग करने वालों के लिए है?
                            </strong>
                            <p className="page-para">
                                aawaz.com ऐप <a href="http://bit.ly/2FrVEz4">गूगल प्ले स्टोर </a> पर ऐप के तौर पर उपलब्ध है। आईफोन उपभोक्ता <a href="http://www.aawaz.com">www.aawaz.com</a> पर जाकर उपलब्ध सामग्री सुन और पढ़ सकते हैं।
                            </p>
                            <br />
                            <strong className="panel-title">
                                क्या यह ऐप ऐप स्टोर में उपलब्ध है?
                            </strong>
                            <p className="page-para">
                                IOS उपभोक्ताओं के लिए फिलहाल ऐप उपलब्ध नहीं है. लेकिन आप <a href="http://www.aawaz.com">www.aawaz.com</a> पर इसे सुन और पढ़ सकते हैं।
                            </p>
                            <br />
                            <strong className="panel-title">
                                aawaz ऐप मुफ्त है या पैसा देना होगा?
                            </strong>
                            <p className="page-para">
                                <a href="http://www.aawaz.com">Aawaz.com</a> वेब पर निःशुल्क उपलब्ध है। आप अपने एंड्रॉयड फोन पर भी इस ऐप को <a href="http://bit.ly/2FrVEz4">गूगल प्ले स्टोर </a> में जाकर डाउनलोड कर सकते हैं।
                            </p>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Faq;