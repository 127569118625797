import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import auth from '../../../../services/authentication';
import { Container, Row, Col, Card } from 'react-bootstrap';
let option = {
    random: [Math.floor(Math.random() * 18)],
    random2: [Math.floor(Math.random() * 10)]
}
class footerPopularPoadcast extends React.Component {
    state = {
        popular: []
    }

    componentDidMount() {
        if (this.state.popular.length === 0) {
            axios.get(`https://prod.aawaz.com/web/dashboard-v2/sec-2a7224/`, { auth: auth })
                .then(res => {
                    const random = [Math.floor(Math.random() * res.data.items.length)]
                    const random2 = [Math.floor(Math.random() * res.data.items.length)]
                    const popular = res.data.items.slice(random, random2).length === 0 || res.data.items.slice(random, random2).length <= 5 ? res.data.items.slice(0, 6) : res.data.items.slice(random, random2)
                    this.setState({ popular });
                })
        }
    }
    [Math.floor(Math.random() * this.state.popular.length)]
    render() {
        return (

            <div>
                <ul className="footer-menu">
                    {this.state.popular.slice(0, 6).map((popular, i) => {
                        return (
                            <li key={popular.uniqueSlug}>
                                <Link to={`/show/` + popular.showShortUrl}>
                                    {popular.title}
                                </Link>
                            </li>
                        )
                    })}

                </ul>
            </div>
        )
    }
}
export default footerPopularPoadcast