import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

const LiveLoader = props => {
    // Hardcoded values
    const rows = 2
    const columns = 2
    const coverHeight = 205
    const coverWidth = 500
    const padding = 50

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        // <div style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
        <div style={{ marginTop: "30px" }}>

            <Row>

                <Col>

                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={rows * coverHeightWithPadding}
                        // foregroundColor="#808080"
                        backgroundColor="#808080"
                        // baseUrl={window.location.pathname === "/" ? "/" : window.location.pathname === "/channels" ? "/channels" : "/category/:id"}
                        {...props}
                    >
                        {covers.map((g, i) => {
                            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                            return (
                                <rect
                                    key={i}
                                    x={vx}
                                    y={vy}
                                    rx="10"
                                    ry="10"
                                    width={coverWidth}
                                    height={coverHeight}
                                />
                            )
                        })}
                    </ContentLoader>
                </Col>

            </Row>
        </div>
        // </div>
    )
}


export default LiveLoader