import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, CardImg, Container } from 'react-bootstrap'
import AgoraRTC from "agora-rtc-sdk";
import classes from "./shows.module.css"
import axios from 'axios'
import liveimg from './img/live.gif'
import pauseimg from './img/pause.png'
import logo from './img/aawaz-280.png'
import { getAllShows, CheckLive, BroLive } from './services/liveservices'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowMoreText from 'react-show-more-text';
// import moment from 'moment';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-122455299-1', { standardImplementation: true });




const Shows = (props) => {
    const [data, setdata] = useState([])
    const [broLive, setbroLive] = useState([])
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        getAllShows()
            .then((res) => {
                setdata(res.data)
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        BroLive()
            .then(res => {
                setbroLive(res.data.items)
                console.log("Live", res.data.items)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])


    function alertAndPush() {
        var r = window.confirm("Press a button!");
        if (r === true) {

        } else {
        }
    }
    const Golive = (title, uniqueSlug) => {
        CheckLive()
            .then((res) => {
                let data = res.data.data.channels

                let data1 = data.filter(item => {
                    return item.channelName === title
                })
                if (data1.length !== 0) {
                    let cha = data1[0].channelName
                    let usercount = data1[0].userCount
                    if (title == cha) {
                        usercount > 0 ? history.push(`/live/${uniqueSlug}`) : toast.dark("Stream Not Started Yet !!")
                    }
                } else {
                    toast.dark("Stream Not Started Yet !!")
                }

            })
            .catch(err => {
                console.log(err)
            })


    }

    const Timer = (id, start) => {

        if (id !== null && start !== "") {
            console.log("enter", id, start)
            //  Set the date we're counting down to
            var countDownDate = new Date(start * 1000).getTime();
            // Update the count down every 1 second
            var x = setInterval(function () {
                // Get today's date and time
                var now = new Date().getTime();
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                // Output the result in an element with id="demo"


                let Ids = document.getElementById(`${id}`)
                if (Ids !== null) {
                    Ids.innerHTML =
                        days + "day " +
                        hours + "h " +
                        minutes + "m " + seconds + "s "
                } else {
                    return
                }


                // If the count down is over, write some text 

            }, 1000);
        }

    }
    const Brolive = (data) => {

        let palyData = [{
            title: data[0].title,
            episodeDuration: null,
            audioFileUrl: data[0].audioUrl,
            shortDescription: data[0].title,
            episodeNo: "1",
            uniqueSlug: data[0].uniqueSlug
        }]

        props.handleBrolive(palyData)
    }
    var countDownDate = new Date(1630063800).getTime();
    const history = useHistory()
    return (

        <Container style={{ marginTop: "20px" }}>
            {/* <div> */}
            <Row>
                {
                    data.map((result, i) =>
                        <>
                            {
                                result.shows.map((item, i) => {
                                    return (
                                        <Col lg={6} key={item.id} style={{ marginBottom: "12px" }}>
                                            <div className={classes.showGrid}>
                                                <img className={classes.liveShowImg} src={item.featuredImageUrl} alt="img" />
                                                <div className={classes.liveContent}>
                                                    <h1 className={classes.liveShwTitle}>{item.title}</h1>
                                                    <p className="episodeDesc" style={{ padding: '5px', }}>
                                                        <ShowMoreText
                                                            /* Default options */
                                                            lines={2}
                                                            more='Read more'
                                                            less='Show less'
                                                            expanded={false}
                                                            className='showDetails-style'
                                                            keepNewLines={false}
                                                            anchorClass='showDetails'
                                                            width={280}
                                                        >
                                                            {item.description}
                                                        </ShowMoreText>
                                                    </p>
                                                    {/* <p className={classes.liveShwSubtitle}>{item.description}</p> */}
                                                    <p>{item.startTime}	&nbsp;	&nbsp;{result.name === "Upcoming Shows" ? <p id={`${item.currentSlug}`}>{Timer(item.currentSlug, item.beginTime)}</p> :
                                                        <button className={classes.liveBtn} onClick={() => Golive(item.title, item.uniqueSlug)}><i class="fa fa-play" aria-hidden="true"></i>  Listen Now </button>
                                                    }</p>

                                                </div>
                                            </div>
                                        </Col>

                                    )
                                })


                            }

                        </>
                    )}

            </Row>

            <>
                <h4 style={{ marginTop: "12px", marginBottom: "12px" }}> Live Episode</h4>
                {
                    broLive.map((item, i) => {
                        return (
                            <Col lg={6} key={item.id} style={{ marginBottom: "12px" }}>
                                <div className={classes.showGrid}>
                                    <img style={{ width: "173px" }} src={item.featuredImageUrl} alt="img" />
                                    <div >
                                        <h1 className={classes.liveShwTitle}>{item.title}</h1>
                                        <button className={classes.liveBtn} onClick={() => Brolive(broLive)}><i class="fa fa-play" aria-hidden="true"></i>  Listen Now </button>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </>
        </Container >
    )
}


export default Shows
