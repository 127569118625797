import React, { Component } from 'react';
import { withGoogleyolo } from 'react-googleyolo'
// ...
import firebase from 'firebase/app';
// import { authenticateWithYourBackend } from '../api'
 

class YoloLogin extends Component {
    /*data = null;
    // Hold a reference to the anonymous current user.
    anonymousUser = firebase.auth().currentUser;
    state = { isLoading: false }

    retrieve = googleyolo =>
    googleyolo.retrieve().then(
      credential => {
        this.setState({ isLoading: false })
 
        // imaginary method, where you hit your backend with the idToken
        // to verify it really is valid, then sign them in and get their User object back
        authenticateWithYourBackend(credential.idToken).then(user => {
          this.setState(user)
        })
      },
      error => {
        this.setState({ isLoading: false })
      }
    )

    componentDidMount() {
        const { googleyolo } = this.props
        if (googleyolo) {
          this.setState({ isLoading: true })
          this.retrieve(googleyolo)
        }
      }
     
      componentDidUpdate(prevProps) {
        const { googleyolo } = this.props
        const { isLoading } = this.state
     
        if (googleyolo & !isLoading && !prevProps.googleyolo) {
          this.setState({ isLoading: true })
          this.retrieve(googleyolo)
        }
      }

    render() { 
        const { isLoading, user } = this.state
        if (isLoading) {
          return <div>Loading auth...</div>
        }
     
        if (user) {
          return <div>You are logged in as {user.displayName}</div>
        }
     
        // Imaginary component that signs you in
        // It probably also uses `googleyolo` to call `.hint()`!
        return <GoogleLogin />
      }*/
    }

 
export default YoloLogin;
