

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import auth from '../../../../services/authentication'
import { Link, useHistory } from 'react-router-dom'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import SidebarLoader from '../../../HomeSection/HomeComponent/Loader/SidebarLoader'


function ChannelTab() {
    const [channels, setchannels] = useState([])
    const [Loader, setLoader] = useState(true)
    let history = useHistory()
    useEffect(() => {

        axios.get(`https://prod.aawaz.com/web/channel-details/?all=True`, { auth, auth })
            .then(res => {
                setchannels(res.data);
                setLoader(false)

            })
    }, [])
    const ClickedCha = (data) => {
        console.log(data)
        history.push(`/channel/${data}`)
    }
    return (
        <div className="channel-sec">
            {Loader ? <SidebarLoader />

                :
                <ul className="tab-container" style={{ marginBottom: "70px" }}>

                    {
                        channels.map(channel =>

                            <Menu iconShape="circle" className="channel-tab" key={channel.uniqueSlug}>
                                <MenuItem
                                    icon={<img src={channel.featuredImageUrl} onClick={() => ClickedCha(channel.shortUrl)} className="sidebar-icon" />}
                                >
                                    <Link to={`/channel/${channel.shortUrl}`} data-toggle="tooltip" data-placement="bottom" title={channel.title}>{channel.title}</Link>

                                </MenuItem>
                                {/* <p>{category.iconImageUrl}</p> */}
                            </Menu>
                        )
                    }

                </ul>
            }
        </div>
    )
}

export default ChannelTab
