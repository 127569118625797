import React, { Component } from 'react';
import axios from 'axios';
import auth from '../../../../services/authentication';
import { Link } from 'react-router-dom'
import { Row, Col, Container, Card } from 'react-bootstrap';

class CategoryList extends React.Component {
  state = {
    categories: []
  }

  componentDidMount() {
    if (this.state.categories.length === 0) {
      axios.get(`https://prod.aawaz.com/web/category-details-v2/?limit=100`, { auth: auth })
        .then(res => {
          const categories = res.data.results;
          this.setState({ categories });
        })
    }
  }

  render() {
    return (
      <div>
        <ul className="footer-menu">
          {this.state.categories.map(category =>
            <li key={category.uniqueSlug}>
              <Link to={`/category/` + category.shortUrl}>
              {category.name}
              </Link>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default CategoryList;