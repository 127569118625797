import React, { Component } from 'react';
import fbBtn from '../../../../img/fb.png'
import tweetBtn from '../../../../img/twitter.png'
import whatsBtn from '../../../../img/whatsapp.png'
import Logo from '../../../../img/logo.png';
import FooterFb from '../../../../img/svg/fb.svg';
import FooterInsta from '../../../../img/svg/Insta.svg';
import FooterTwitter from '../../../../img/svg/twitter.svg';
import FooterYoutube from '../../../../img/svg/youtube.svg';
import BorderTop from '../../../../img/borderTop.png';






class footerSocial extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-4 col-centered socio-sec">

                        <ul className="social-menu">
                            <li> <img src={Logo} className="logo" /> </li>
                            <li> <a href={`https://www.facebook.com/aawazDotCom/`} target="_blank"> <img src={FooterFb} className="sub-icon" /></a></li>
                            <li> <a href={`https://www.instagram.com/aawazdotcom/`} target="_blank"> <img src={FooterInsta} className="sub-icon" /></a> </li>
                            <li> <a href={`https://twitter.com/AawazDotCom`} target="_blank"> <img src={FooterTwitter} className="sub-icon" /></a> </li>
                            <li> <a href={`https://www.youtube.com/channel/UCGWJp1r_X2jJBlWkJgXn0CA`} target="_blank"> <img src={FooterYoutube} className="sub-icon" /></a> </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default footerSocial;