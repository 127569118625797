import React from "react";
// import { Tabs, TabLink, TabContent } from 'react-tabs-redux';
import { Tabs, Tab } from 'react-bootstrap'
import CategoriesTab from './CategoriesTab';
import ChannelTab from './ChannelTabs';

class SideBarNav extends React.Component {
    render() {
        return (
            <div>
                {/* <Tabs>
                    <TabLink className="sidebar-btn" to="tab1">Categories</TabLink>
                    <TabLink className="sidebar-btn" to="tab2">Channels</TabLink>

                    <TabContent className="sidebar-sec" for="tab1">
                        <CategoriesTab />
                    </TabContent>
                    <TabContent className="sidebar-sec" for="tab2">
                        <ChannelTab />
                    </TabContent>
                </Tabs> */}
                <Tabs
                    defaultActiveKey="Categories"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-6"
                >
                    <Tab eventKey="Categories" title="Categories">
                        <CategoriesTab />
                    </Tab>
                    <Tab eventKey="Channel" title="Channels" >
                        <ChannelTab />
                    </Tab>
                </Tabs>
            </div>
        )

    }
}


export default SideBarNav