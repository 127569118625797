import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon

} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

function Share() {

    const style = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        display: 'flex',
        justifyContent: 'space-between'
    };
    const style2 = {
        background: 'black',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '10px 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        display: "flex",
        justifyContent: "space-between"
    };

    return (
        <div>
            <div style={style}>
                <FacebookShareButton url={window.location.href} >
                    <FacebookIcon
                        size={30}
                        round={true}
                        logoFillColor={"black"}
                        iconBgStyle={{ fill: "white" }}
                    />
                </FacebookShareButton>
                <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon
                        size={30}
                        round={true}
                        logoFillColor={"black"}
                        iconBgStyle={{ fill: "white" }}
                    />
                </WhatsappShareButton>
                <LinkedinShareButton url={window.location.href}>
                    <LinkedinIcon
                        size={30}
                        round={true}
                        logoFillColor={"black"}
                        iconBgStyle={{ fill: "white" }}
                    />
                </LinkedinShareButton>
                <TwitterShareButton url={window.location.href}>
                    <TwitterIcon
                        size={30}
                        round={true}
                        logoFillColor={"black"}
                        iconBgStyle={{ fill: "white" }}
                    />
                </TwitterShareButton>
                <EmailShareButton url={window.location.href}>
                    <EmailIcon
                        size={30}
                        round={true}
                        logoFillColor={"black"}
                        iconBgStyle={{ fill: "white" }}
                    />
                </EmailShareButton>
            </div>
            <div style={style2}><div style={{ overflow: 'auto', overflowY: 'hidden', padding: '11px' }}>{window.location.href}</div> <div><CopyToClipboard text={window.location.href} onCopy={() => toast.info('Link copied to clipboard!')}><div style={{ cursor: "pointer", padding: "11px 14px" }}>Copy</div></CopyToClipboard></div></div>

        </div>
    )
}

export default Share
