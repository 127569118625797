import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Form, ListGroup, Card, Row, Col, Modal, Button, Spinner, Container } from 'react-bootstrap'
import { postSearch } from '../../../services/GetServices';
import firebase from 'firebase';
import voiceBtn from "../../../img/icons/ic_search_voice.svg";


class HeaderSearch extends Component {

    state = {
        search: "",
        searchFound: false,
        searchResults: [],
        searchedFor: "",
        isGetLoading: false,
        isSearch: false,
        isOpen: false
    }

    typingTimer = null;

    dsearchOnChangeHandler = (event) => {
        this.setState({ search: event.target.value, isGetLoading: event.target.value.length > 0 ? true : false }, () => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                if (this.state.search !== "") {
                    postSearch({ search: this.state.search })
                        .then(response => {
                            if (response.data.length !== 0) {
                                console.log(response.data);
                                this.setState({ searchResult: response.data, isSearch: true, searchFound: true, searchedFor: this.state.search, isGetLoading: false }, () => {
                                    window.Moengage.track_event("Desktop Header Search", {
                                        "query": this.state.search,
                                        "user_id": firebase.auth().currentUser.uid,
                                        "results": response.data,
                                        "type": "Show"
                                    })
                                });
                            }
                            else {
                                this.setState({
                                    isSearch: true,
                                    searchFound: false,
                                    searchedFor: this.state.search
                                })
                            }
                        })
                        .catch(err => console.log(err))
                }
            }, 1000)

        })
    }
    showClickHandler = (url) => {
        this.props.history.push(`/show/${url}`)
    }

    fetchShowData = (url) => {
        this.props.getShowData(url)
        this.props.history.push(`/show/${url}`)
    }

    fetchCategoryData = (url) => {
        this.props.getCategoryData(url)
        // this.props.history.push(`/category/${url}`)
    }

    fetchChannelData = (url) => {
        this.props.getChannelData(url)
        // this.props.history.push(`/channel/${url}`)
    }


    componentDidMount = () => {
        // if (this.props.searchData.length === 0){
        //     this.props.getSearchData()
        // }
    }
    render() {
        return (
            <div>

                <Container fluid className="searchDiv">
                    <div>
                        <Form.Control style={{ padding: '15px' }} autoComplete="on" type="search" value={this.state.search} placeholder="Search aawaz.com" className="form-control-input desktop-input" onChange={(event) => this.dsearchOnChangeHandler(event)} />
                        <img src={voiceBtn} className="desktopsearch-btn" onClick={this.openModal} />
                    </div>
            
                    {this.state.isGetLoading ?
                        <Row className="justify-content-md-center">
                            <Col style={{ textAlign: 'center', paddingTop: '10px' }}>
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                        : null}
                    <div className="searchbox">
                        <div className="dropdown-content">
                            {/* <div className="searchbox-title"><h4>Search Results for <span className="search-result-text">"{this.state.searchedFor}"</span></h4></div> */}
                            {this.state.isSearch && !this.state.isGetLoading && this.state.search.length !== 0 ?
                                <Container fluid className="searchResults" style={{ display: "none" }}>
                                    <Row>
                                        {/* <h4>Search result for "{this.state.searchedFor}"</h4> */}

                                        {this.state.searchFound ?
                                            <div className="wrapper">
                                                {this.state.searchResult.map((data, index) =>

                                                    <div className="showItem" key={index} lg={12} onClick={() => this.showClickHandler(data.shortUrl)} >

                                                        <Card className="showCard">
                                                            {/* <h4 className="showTitle">{data.title}</h4> */}
                                                            <ListGroup key={index + 3} variant="flush">
                                                                <Row>
                                                                    <Col>
                                                                        <Card.Img className="showImg" variant="top" src={data.featuredImageUrl} />
                                                                    </Col>
                                                                    <Col>
                                                                        <ListGroup.Item><p className="showTitle">{data.title}</p></ListGroup.Item>
                                                                    </Col>
                                                                </Row>
                                                            </ListGroup>
                                                        </Card>
                                                        {/* <img alt="" src={data.featuredImageUrl} className="ShowImage" /> */}
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <p>No Data Found</p>
                                        }
                                    </Row>
                                </Container>
                                :
                                null
                            }

                        </div>

                    </div>

                </Container>
            </div>
        )
    }
}
export default withRouter(HeaderSearch);