import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

const ChannelLoader = props => {
    // Hardcoded values
    const rows = 5
    const columns = 3
    const coverHeight = 190
    const coverWidth = 350
    const padding = 25

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        // <div style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
        <Container className="cat_page-content">
            <ContentLoader
                className="page-title"
                speed={1}
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={70}
                backgroundColor="#808080"
                {...props}
            >
                <rect
                    x="0"
                    y="0"
                    rx="10"
                    ry="10"
                    width={"55%"}
                    height="20"
                />


            </ContentLoader>
            <Row>

                <Col className="category-grid2" >

                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={rows * coverHeightWithPadding}
                        backgroundColor="#808080"
                        {...props}
                    >
                        {covers.map((g, i) => {
                            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                            return (
                                <rect
                                    key={i}
                                    x={vx}
                                    y={vy}
                                    rx="10"
                                    ry="10"
                                    width={coverWidth}
                                    height={coverHeight}
                                />
                            )
                        })}
                    </ContentLoader>
                </Col>

            </Row>
        </Container>
    )
}


export default ChannelLoader