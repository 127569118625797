import React from 'react'
import ContentLoader from 'react-content-loader'

const HomepageLoader = props => {
    const rows = 7
    const columns = 9
    const coverHeight = 195
    const coverWidth = 185
    const padding = 20
    const speed = 12

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    const rows2 = 1
    const columns2 = 6
    const coverHeight2 = 105
    const coverWidth2 = 285
    const padding2 = 40
    const speed2 = 12

    const coverHeightWithPadding2 = coverHeight2 + padding2
    const coverWidthWithPadding2 = coverWidth2 + padding2
    const initial2 = 1
    const covers2 = Array(columns2 * rows2).fill(1)

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "100px" }}>
            <ContentLoader
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={50}
                // foregroundColor="#808080"
                backgroundColor="#808080"
                {...props}
            >
                <rect x="910" y="0" rx="15" ry="15" width="25%" height="50" />
                <rect x="10" y="0" rx="10" ry="10" width="50%" height="50" />
            </ContentLoader>
            <ContentLoader
                speed={1}
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={200}
                // foregroundColor="#808080"
                backgroundColor="#808080"
                {...props}
            >
                {covers2.map((g, i) => {
                    let vy = Math.floor(i / columns2) * coverHeightWithPadding2 + initial2
                    let vx = (i * coverWidthWithPadding2) % (columns2 * coverWidthWithPadding2)
                    return (
                        <rect
                            key={i}
                            x={vx}
                            y={30}
                            rx="10"
                            ry="10"
                            width={coverWidth2}
                            height={coverHeight2}
                        />
                    )
                })}
            </ContentLoader>



            <ContentLoader
                speed={1}
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={rows * coverHeightWithPadding}
                // foregroundColor="#808080"
                backgroundColor="#808080"
                {...props}
            >
                {covers.map((g, i) => {
                    let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                    let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                    return (
                        <rect
                            key={i}
                            x={vx}
                            y={vy}
                            rx="10"
                            ry="10"
                            width={coverWidth}
                            height={coverHeight}
                        />
                    )
                })}
            </ContentLoader>
        </div>
    )
}


export default HomepageLoader