import React, { Component } from 'react';
import Airtel from '../../../../img/clients/airtel.png';
import AmazonAlexa from '../../../../img/clients/Amazon_Alexa.png';
import Carvaan from '../../../../img/clients/carvaan.png';
import PlayStore from '../../../../img/clients/play_store.png';
import Firestick from '../../../../img/clients/firestick.png';
import IndusApp from '../../../../img/clients/indus_app.png';
import Ios from '../../../../img/clients/ios.png';
import Paytm from '../../../../img/clients/paytm.png';
import Spotify from '../../../../img/clients/spotify.png';
import OlaPlay from '../../../../img/clients/ola_play.png';

class footerClient extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    
                    <div className="col-lg-12 col-centered socio-sec">
                        <h5 className="client-title">Listen Aawaz on</h5>
                        <ul className="client-logo">
                            <li> <img src={PlayStore} className="sub-icon" /> </li>
                            <li><img src={Ios} className="sub-icon" /></li>
                            <li><img src={Spotify} className="sub-icon" /></li>
                            <li> <img src={Airtel} className="sub-icon" /> </li>
                            <li> <img src={Firestick} className="sub-icon" /> </li>
                            <li><img src={OlaPlay} className="sub-icon" /></li>
                            <li> <img src={AmazonAlexa} className="sub-icon" /></li>
                            <li><img src={Carvaan} className="sub-icon" /></li>
                            <li><img src={Paytm} className="sub-icon" /></li>
                            <li><img src={IndusApp} className="sub-icon" /></li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default footerClient;