import React, { useState, useEffect } from 'react';
import Thumbnail from '../../../img/thumbnail.jpg';
import './read-responsive.css';
import axios from 'axios'
import { useHistory } from 'react-router';
import { withRouter, Link } from 'react-router-dom';

const Read = () => {
    const [Data, setData] = useState([])
    const [Cat, setCat] = useState([])
    const [id, setid] = useState(420)
    const history = useHistory()

    const OnLoad = (id) => {
        axios.get(`https://read.aawaz.com/api/live/read/getArticlesByCategory/language/${id}/5`)
            .then((res) => {
                console.log("read data", res.data)
                setData(res.data)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
    }
    useEffect(() => {
        axios.get(`https://read.aawaz.com/api/live/read/getReadCategories`)
            .then((res) => {
                console.log("read data", res.data)
                setCat(res.data)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
        OnLoad(420)
    }, [])
    useEffect(() => {
        OnLoad(id)
    }, [id])
    return (
        <div>
            <div>
                <div className="container">
                    <div className="row">
                        {Cat.map((item) => (
                            <div className="col" key={item.category_id}>
                                <h4 key={item.category_id} onClick={() => setid(item.category_id)}>{item.category_name}</h4>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        {Data.map((item) => (
                            <div className="col-3">
                                <div className="card" key={item.category_id} onClick={() => history.push(`/single-read/${item.article_id}`)}>
                                    <span className="read-category-name">
                                        <p>{item.category_name}</p>
                                    </span>
                                    <img onLoad={(e) => { e.target.src = item.image }} src={Thumbnail} onError={(e) => { e.currentTarget.src = Thumbnail }} className="post-img" alt="" />
                                    <div className="post-mid-band">
                                        <p className="author-name">{item.author}<span className="pubdate">{item.pubDate}</span> </p>
                                    </div>
                                    <h2 className="post-name">{item.title}</h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Read)