import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

function SidebarLoader(props) {
    const rows = 9
    const columns = 1
    const coverHeight = 190
    const coverWidth = 350
    const padding = 25

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        <div>
            <Container >
                <Row>
                    <Col className="category-grid2" >
                        {covers.map((g, i) => {
                            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                            return (
                                <ContentLoader
                                    speed={1}
                                    backgroundOpacity={0.1}
                                    foregroundOpacity={0.3}
                                    width={"100%"}
                                    height={80}
                                    backgroundColor="#808080"
                                    {...props}
                                >
                                    <circle cx="25" cy="35" r="25" />
                                    <rect x="60" y="27" rx="5" ry="5" width="50%" height="14" />
                                </ContentLoader>
                            )
                        })}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SidebarLoader
