import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import CategoryList from '../CategoryPage/CategoryList'
import ChannelList from '../ChannelPage/ChannelList';

import '../../../css/pages.css';
import LiveShows from '../Livepages/Shows'
import ShowList from '../Livepages/ShowList';

const NavTab = props => {
    let history = useHistory()
    let data = props.data;
    // let name = props.match.params.index
    // console.log('props before effect: ', props)
    useEffect(() => {
        // if (props.match.params.index === "2") {
        //     history.push('/live-shows')
        // } else if (data.length === 0) {
        //     props.getSearchData()
        // } else {

        // }
        if (data.length === 0) props.getSearchData()
    }, [props.match.params.index])
    let onUserSelect = (shortUrl) => {
        if (props.match.params.index === "0") {
            props.getChannelData(shortUrl)
            console.log('channel called')
        } else {
            console.log('category called')
            props.getCategoryData(shortUrl)
        }
    }
    console.log("nav", props.data)
    return (
        <Container className="navTabMain">
            <div>
                {!props.isLoading ?
                    <>
                        {
                            props.match.params.index !== "2" ?
                                <>
                                    {props.match.params.index !== "1" ?

                                        <ChannelList
                                            isLoading={props.isLoading}
                                            data={props.searchAPIData}
                                            getSearchData={props.getSearchData}
                                            getCategoryData={props.getCategoryData}
                                            getChannelData={props.getChannelData}
                                            searchData={props.searchAPIData}
                                            getShowData={props.getShowData}
                                        />
                                        :

                                        <CategoryList
                                            isLoading={props.isLoading}
                                            data={props.searchAPIData}
                                            getSearchData={props.getSearchData}
                                            getCategoryData={props.getCategoryData}
                                            getChannelData={props.getChannelData}
                                            searchData={props.searchAPIData}
                                            getShowData={props.getShowData} />
                                    }

                                </>

                                :
                                <ShowList
                                    playlist={props.playlist}
                                    getShowData={props.getShowData}
                                    data={props.Showdata} handleBrolive={props.handleBrolive}
                                    isLoading={props.isLoading}
                                />
                        }
                    </>
                    :
                    <p>Loading...</p>
                }
            </div>
        </Container>
    );
}

export default withRouter(NavTab);